import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getInvestor = async (id) => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/profile/view?id=${id}&expand=wallet,transactions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getInvestors = async (expand = "") => {
  try {
    const data = await api.get(
      `${process.env.REACT_APP_API}/v1/profile?expand=${expand}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const deleteUser = async (id) => {
  console.log(id);
  try {
    const data = await api.delete(
      `${process.env.REACT_APP_API}/v1/user/delete?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useInvestorFetchRequest(id) {
  return useQuery(["adminInvestor"], () => getInvestor(id), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}

export function useInvestorsFetchRequest(expand) {
  return useQuery(["adminInvestors"], () => getInvestors(expand), {
    onSuccess: (data) => {
      return data.data.data;
    }
  });
}

export function useDeleteUser(data) {
  return deleteUser(data);
}
