import { Card, Col, Statistic } from "antd";
import React from "react";

const formatColor = {
  amount: "#3f8600",
  expense: "#f5222d",
  profit: "#1890ff"
};

const Statistics = ({ title, amount, suffix, color, isLoading }) => {
  return (
    <Col
      xs={{ flex: "100%" }}
      sm={{ flex: "50%" }}
      md={{ flex: "50%" }}
      lg={{ flex: "25%" }}
      xl={{ flex: "25%" }}
      className="mb-24"
    >
      <Card bordered={false} size="small" className="criclebox h-full">
        <Statistic
          loading={isLoading}
          title={title}
          suffix={suffix ? ` / ${suffix}` : ""}
          value={amount}
          valueStyle={{
            color: color ? formatColor[color] : "#000000"
          }}
        />
      </Card>
    </Col>
  );
};

export default Statistics;
