import React, { useState } from "react";
import {
  useContractRequest,
  useContractsRequest
} from "../../Hooks/contracts/useContracts.query";
import { Flex, Divider, Card, Table, Modal, Button } from "antd";
import { Link } from "react-router-dom";

const Contracts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isLoading } = useContractsRequest();

  const columns = [
    {
      title: "Contrato ID",
      dataIndex: "contract_number",
      key: "contract_number",
      width: "15%"
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Cliente",
      dataIndex: "profile",
      key: "profile",
      render: (text) => {
        return (
          <Link to={`/investidor/perfil/${text?.id}/informacoes-pessoais`}>
            {text?.name}
          </Link>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status_ZPS",
      key: "status_ZPS",
      render: (text) => {
        return <p>{!text ? "Não enviado" : text}</p>;
      }
    },
    {
      title: "Visualizar",
      dataIndex: "user_id",
      key: "user_id",
      width: "10%",
      render: (text) => (
        <>
          <a href={`/contratos/${text}/detalhes`}>Visualizar</a>
        </>
      )
    }
  ];

  const handleOpenModalContract = (e) => {
    console.log(e);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center"></Flex>
      <Divider />
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Contratos"
      >
        <Table
          tableLayout="auto"
          loading={isLoading ? true : false}
          columns={columns}
          dataSource={data}
          pagination={false}
          className="ant-border-space custom-row-hover"
          bordered
        />

        <Modal
          title=""
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose={true}
          maskClosable={false}
          okText="Tem certeza?"
          confirmLoading
          maskStyle={{ backdropFilter: "10px" }}
          footer={true}
        ></Modal>
      </Card>
    </div>
  );
};

export default Contracts;
