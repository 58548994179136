import React, { Fragment } from "react";
import { typeFormat } from "../../helpers/formatType";
import { formatCurrency } from "../../helpers/moneyFormat";

function Report00001Pdf({ data, amount, title }) {
  return (
    <>
      <table width={"100%"}>
        <tr>
          <td>
            <h1>{title}</h1>
          </td>
        </tr>
      </table>

      {data?.map((item, index) => {
        return (
          <Fragment key={index}>
            <table
              border={0}
              width={"100%"}
              key={index}
              style={{ margin: "10px 0" }}
            >
              <tr style={{ backgroundColor: "#ccc", padding: 10, height: 40 }}>
                <th colSpan={2} width={"70%"}>
                  {item.profile?.name}
                </th>
                <th style={{ textAlign: "center" }}>
                  {item.profile?.document} / {item.profile?.account_number}
                </th>
              </tr>
              <tr>
                <td>Email: {item.profile?.email}</td>
                <td>Gerente: {item.profile?.manager?.name}</td>
                <td>Moeda: {item.profile?.currency}</td>
              </tr>

              <tr
                style={{
                  backgroundColor: "#ececec",
                  margin: 10,
                  height: 20,
                  textAlign: "center"
                }}
              >
                <th colSpan={3}>Transações</th>
              </tr>
              <tr>
                <td colSpan={4}>
                  <table
                    width={"100%"}
                    border={0}
                    className="table-transactions"
                  >
                    <tr className=".table-transactions ">
                      <th width={"15%"}>Data</th>
                      <th width={"25%"}>tipo</th>
                      <th width={"20%"}>percentual</th>
                      <th width={"20%"}>Valor</th>
                      <th width={"10%"}>Status</th>
                    </tr>
                    {item?.transactions?.map((tr) => (
                      <tr key={tr.id}>
                        <td>{tr.date}</td>
                        <td>{typeFormat(tr.type_transaction)}</td>
                        <td>{tr.percent || "-"}</td>
                        <td>
                          {formatCurrency(tr.amount_money)}
                          {tr.base_calculation_percentage ? (
                            <small>{` - calculo base: ${tr.base_calculation_percentage}%`}</small>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{tr.status}</td>
                      </tr>
                    ))}
                  </table>
                </td>
              </tr>

              {item?.wallet ? (
                <>
                  <tr
                    style={{
                      backgroundColor: "#ececec",
                      margin: 10,
                      height: 20,
                      textAlign: "center"
                    }}
                  >
                    <th colSpan={3}>Carteira</th>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      <table
                        width={"100%"}
                        border={0}
                        className="table-transactions"
                      >
                        <tr>
                          <th>Valor</th>
                          <th>Sacado</th>
                          <th>Disponível</th>
                        </tr>
                        <tr>
                          <td>{item?.wallet?.amount}</td>
                          <td>{item?.wallet?.expense}</td>
                          <td>{item?.wallet?.available_for_withdrawal}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
            </table>
            <hr />
          </Fragment>
        );
      })}

      {amount ? (
        <>
          <br />
          <br />
          <table width={"100%"}>
            <tr
              style={{
                backgroundColor: "#ececec",
                margin: 10,
                height: 20,
                textAlign: "center"
              }}
            >
              <th colSpan={3}>Valores Totais</th>
            </tr>

            <tr>
              <td>Valores Carteira</td>
              <td>Valores Saida</td>
              <td>Valores Disponivel para saque</td>
            </tr>

            <tr>
              {amount?.map((item, index) => {
                return <td key={index}>{formatCurrency(item)}</td>;
              })}
            </tr>
          </table>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Report00001Pdf;
