import React from "react";
import { useManagersGainsRequest } from "../../Hooks/manager/useManager.query";
import { Button, Divider, Flex, List, Table, Typography } from "antd";
import { NavLink } from "react-router-dom";

const columns = [
  {
    title: "Gerente",
    dataIndex: "user_id",
    key: "user_id"
  },
  {
    title: "Valor",
    dataIndex: "amount",
    key: "amount"
  },
  {
    title: "Data",
    dataIndex: "month",
    key: "month"
  }
];
const ManagerGain = () => {
  const { data, isLoading, isError, error } = useManagersGainsRequest();
  const managers = data?.data.data;

  const dataManagers = managers?.map((manager) => {
    const des = manager?.description;
    let listaOrdenada = [];
    if (des) {
      const arrayDes = JSON.parse(des);
      listaOrdenada = arrayDes.map((item, index) => (
        <List
          key={index} // É importante fornecer uma chave única para cada elemento da lista
          dataSource={[item]} // Envolver item em um array para usar no dataSource
          renderItem={(itens) => <List.Item> - {itens}</List.Item>}
        />
      ));
    }

    return {
      key: manager?.id,
      user_id: manager?.user_id,
      amount: manager?.amount,
      month: manager?.month,
      description: listaOrdenada
    };
  });
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        {/* <Button className="" onClick={handleEntryGainsManager}>
          + Novo lançamento
        </Button> */}
      </Flex>
      <Divider />

      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => record.description
          // rowExpandable: (record) => record.name !== "Not Expandable"
        }}
        dataSource={dataManagers}
      />
    </div>
  );
};

export default ManagerGain;
