import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../../services/api";

const getPortfolio = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/managers/portfolio?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
const getManager = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/profile/manager-account?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const getAllManagers = async () => {
  try {
    const { data } = await api.get(
      `${process.env.REACT_APP_API}/v1/profile/managers`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
const getRankingManagers = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/gains-manager/manager-ranking`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data;
  } catch (error) {
    console.log(error.message);
  }
};
const getManagersGains = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/gains-manager`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useManagerFetchRequest(id) {
  return useQuery(["manager", id], async () => await getManager(id), {
    enabled: !!id,
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}
export function useManagerPortfolioRequest(id) {
  return useQuery(["manager", id], async () => await getPortfolio(id), {
    enabled: !!id,
    retry: 5,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.error("error[useManagerPortfolioRequest]: ", error);
    }
  });
}

export function useManagersRequest() {
  return useQuery(["managers"], async () => await getAllManagers(), {
    retry: 5,
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}

export function useRankingManagersRequest() {
  return useQuery(
    ["ranking-managers"],
    async () => await getRankingManagers(),
    {
      retry: 5,
      onSuccess: (data) => {
        return data.data.data;
      },
      onError: (error) => {
        console.log(error);
      }
    }
  );
}

export function useManagersGainsRequest() {
  return useQuery(["gains-manager"], async () => await getManagersGains(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data.data;
    },
    onError: (error) => {
      console.log(error);
    }
  });
}
