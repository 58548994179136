import api from "../../services/api";

const putManagerAccount = async (values) => {
  try {
    const data = await api.patch(
      `${process.env.REACT_APP_API}/v1/profile/update-manager-account?id=${values.user_id}`,
      values.manager_id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useUpdateManager(data) {
  return putManagerAccount(data);
}
