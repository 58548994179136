import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Divider, Flex, Row, Table } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import React from "react";
import { Link, NavLink } from "react-router-dom";

// import { Container } from './styles';
const columns = [
  {
    title: "Plano",
    dataIndex: "plan",
    key: "plan"
  },
  {
    title: "Valor Inicial",
    dataIndex: "initial_value",
    key: "initial_value"
  },
  {
    title: "Tempo de contrato",
    dataIndex: "contract_time",
    key: "contract_time"
  },
  {
    title: "Ganho do G.N",
    dataIndex: "gains_manager",
    key: "gains_manager"
  },
  {
    title: "Ganhos entre",
    dataIndex: "gains_between",
    key: "gains_between"
  },
  {
    title: "Promoção",
    dataIndex: "promotion",
    key: "promotion"
  },
  {
    title: "Açoes",
    dataIndex: "action",
    key: "action",
    render: (text) => (
      <>
        <Link to={`${text}/editar`}>Visualizar</Link>
      </>
    )
  }
];

function Plans() {
  const { data: dataPlans } = useQuery(["planos"], () =>
    axios
      .get(`${process.env.REACT_APP_API}/v1/plan`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        return res.data.data;
      })
  );

  const data = dataPlans?.map((plan) => {
    return {
      key: plan?.id,
      plan: plan?.plan,
      warranty: plan?.warranty,
      price: plan?.price,
      initial_value: plan?.initial_value,
      contract_time: plan?.contract_time,
      gains_manager: plan?.gains_manager,
      gains_between: plan?.gains_between,
      promotion: plan?.promotion,
      action: plan?.id
    };
  });

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Button className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Flex>
      <Divider />
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Planos"
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="ant-border-space"
                bordered
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Plans;
