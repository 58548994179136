import { Avatar, List, Typography } from "antd";
import React from "react";
import { useManagerPortfolioRequest } from "../../Hooks/manager/useManager.query";
import { Link, useParams } from "react-router-dom";
import SkeletonLoading from "../../components/Loading/skeletonLoading";

const { Text, Title } = Typography;

const Investors = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isFetched, isFetching } =
    useManagerPortfolioRequest(id);
  const portfolioData = data;
  console.group();
  console.log("isLoading", isLoading);
  console.log("isError", isError);
  console.log("isFetched", isFetched);
  console.log("isFetching", isFetching);
  console.groupEnd();

  return (
    <div>
      <Title level={4}>Clientes na carteira</Title>

      {isLoading ? (
        <SkeletonLoading />
      ) : (
        <List
          dataSource={data?.clients}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Link
                  to={`/investidor/perfil/${item?.id}/informacoes-pessoais/editar`}
                  key="list-loadmore-edit"
                >
                  editar
                </Link>,
                <Link
                  to={`/investidor/perfil/${item?.id}/informacoes-pessoais`}
                  key="list-loadmore-more"
                >
                  visualizar
                </Link>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={`https://api.dicebear.com/7.x/fun-emoji/svg?seed=${item?.name}`}
                  />
                }
                title={
                  <Link
                    to={`/investidor/perfil/${item?.id}/informacoes-pessoais`}
                  >
                    {item?.name}
                  </Link>
                }
                description={item?.name}
              />
              {/* {item.name} */}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default Investors;
