import { Button, Card, Col, Divider, Flex, Table } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { useOfficesFetchRequest } from "../../Hooks/offices/offices.query";

const columns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Dono",
    dataIndex: "owner",
    key: "owner",
    render: (text, record) => record?.owner?.name
  },
  {
    title: "Telefone / Whatsapp",
    dataIndex: "phone",
    key: "phone",
    render: (text, record) => (
      <>
        {record.phone} / {record.whatsapp}
      </>
    )
  },
  {
    title: "Endereço",
    dataIndex: "officeAddress",
    key: "officeAddress",
    render: (text, record) => text?.street
  },
  {
    title: "Cidade",
    dataIndex: "officeAddress",
    key: "officeAddress",
    render: (text, record) => (
      <>
        {text?.city} - {text?.state}
      </>
    )
  },
  {
    title: "Ações",
    key: "action",
    dataIndex: "action",
    render: (text, record) => (
      <>
        <NavLink to={`${record.id}`}>Acessar</NavLink>
        <NavLink to={`${record.id}/editar`}>Editar</NavLink>
      </>
    )
  }
];
const Offices = () => {
  const { data, isLoading } = useOfficesFetchRequest();
  const offices = data?.data.data;
  console.log(offices);
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Button className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Flex>
      <Divider />

      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Escritorios"
      >
        <Table
          loading={isLoading ? true : false}
          columns={columns}
          dataSource={offices}
          pagination={false}
          className="ant-border-space custom-row-hover"
          bordered
        />
      </Card>
    </div>
  );
};

export default Offices;
