import { useQueryClient } from "@tanstack/react-query";
import { Form, Table, Tabs, notification, Button, Divider } from "antd";
import React, { useRef } from "react";
import { useBalanceRequest } from "../../Hooks/reports/useReports.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import { useReactToPrint } from "react-to-print";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import * as jsonexport from "jsonexport/dist";
import { saveAs } from "file-saver";
import { dateNow } from "../../helpers/dateHelper";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { colors } from "../../Themes";

const { TabPane } = Tabs;

const columns = [
  {
    title: "Nome",
    dataIndex: "NOME",
    key: "nome"
  },
  {
    title: "Aporte",
    dataIndex: "APORTE",
    key: "aporte",
    render: (amount) => formatCurrency(amount)
  },
  {
    title: "Operando",
    dataIndex: "OPERANDO",
    key: "operando",
    render: (amount) => formatCurrency(amount)
  },
  {
    title: "Rendimento",
    dataIndex: "RENDIMENTO",
    key: "rendimento",
    render: (amount) => formatCurrency(amount)
  },
  {
    title: "Transações",
    children: [
      {
        title: "Aporte",
        dataIndex: ["TRANSACOES", "aporte"],
        key: "transacoes_aporte",
        render: (amount) => formatCurrency(amount)
      },
      {
        title: "Reinvestimento",
        dataIndex: ["TRANSACOES", "reinvestimento"],
        key: "transacoes_reinvestimento",
        render: (amount) => formatCurrency(amount)
      },
      {
        title: "Saques",
        dataIndex: ["TRANSACOES", "saques"],
        key: "transacoes_saques",
        render: (amount) => formatCurrency(amount)
      },
      {
        title: "Rentabilidade",
        dataIndex: ["TRANSACOES", "rentabilidade"],
        key: "transacoes_rentabilidade",
        render: (amount) => formatCurrency(amount)
      }
    ]
  }
];

const ReportsBalance = () => {
  const printRef = useRef();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const { data, isLoading } = useBalanceRequest();

  const dollarClients = data?.DOLLAR.investidores.clientes;
  const realClients = data?.REAL.investidores.clientes;
  const all = dollarClients?.concat(realClients);

  const valueInOperation = {
    real: data?.REAL.investidores.total_operacao,
    dollar: data?.DOLLAR.investidores.total_operacao
  };

  console.log(all);

  const dataSourceDollar = data?.DOLLAR.investidores.clientes.map(
    (cliente, index) => ({
      key: index,
      ...cliente
    })
  );
  const dataSourceReal = data?.REAL.investidores.clientes.map(
    (cliente, index) => ({
      key: index,
      ...cliente
    })
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Investidores_Real"
  });

  const downloadCSV = () => {
    const csvData = dataToCSV(all);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `balanco_${Date.now()}.csv`);
  };

  const dataToCSV = (data) => {
    const csvRows = [
      [
        "VALOR OPERANDO",
        ["REAL", valueInOperation.real],
        ["DOLAR", valueInOperation.dollar]
      ],
      ["moeda", "Nome", "Aporte", "Operando", "Reinvestimento", "rendimento"]
    ];
    data.forEach((row) => {
      csvRows.push([
        row.MOEDA,
        row.NOME,
        row.OPERANDO,
        row.RENDIMENTO,
        row.TRANSACOES.reinvestimento
      ]);
    });
    return csvRows.map((e) => e.join(",")).join("\n");
  };

  return (
    <>
      <Button onClick={downloadCSV} icon={<SaveOutlined />}>
        Download CSV
      </Button>

      <Button>
        <ReactHTMLTableToExcel
          style={{ background: "#900" }}
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename={`balanco_${Date.now()}`}
          sheet={`balanco_${Date.now()}`}
          buttonText="Download as XLS"
        />
      </Button>
      <Divider />
      <div>
        <Table
          dataSource={dataSourceDollar}
          columns={columns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          bordered
          title={() => "Investidores em Dólar"}
          footer={() => (
            <div>
              <b>Total Operação:</b>{" "}
              {formatCurrency(data?.DOLLAR.investidores.total_operacao)} <br />{" "}
              <b>Total de Aporte:</b>{" "}
              {formatCurrency(data?.DOLLAR.investidores.total_aporte)}
            </div>
          )}
        />
        <Table
          dataSource={dataSourceReal}
          columns={columns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          bordered
          title={() => "Investidores em Real"}
          footer={() => (
            <div>
              <b>Total Operação:</b>{" "}
              {formatCurrency(data?.REAL.investidores.total_operacao, "BRL")}{" "}
              <br /> <b>Total de Aporte:</b>{" "}
              {formatCurrency(data?.REAL.investidores.total_aporte, "BRL")}
            </div>
          )}
        />
      </div>

      <table id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <tr style={{ background: "#ccc" }}>
            <td colSpan={3} style={{ textAlign: "center" }}>
              Saldo em operação (REAL)
            </td>
            <td colSpan={3}>
              {formatCurrency(data?.REAL.investidores.total_operacao, "BRL")}
            </td>
          </tr>
          <tr style={{ background: "#ccc" }}>
            <td colSpan={3} style={{ textAlign: "center" }}>
              Saldo em operação (DOLLAR)
            </td>
            <td colSpan={3}>
              {formatCurrency(data?.DOLLAR.investidores.total_operacao)}
            </td>
          </tr>
        </thead>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Modeda</th>
            <th>Nome</th>
            <th>Saldo em Aportado</th>
            <th>Saldo em Operação</th>
            <th>Reinvestimento</th>
            <th>Rendimento</th>
          </tr>
        </thead>
        <tbody>
          {all?.map((item) => (
            <tr>
              <td>{item.MOEDA}</td>
              <td>{item.NOME}</td>
              <td>{formatCurrency(item.APORTE, item.MOEDA)}</td>
              <td>{formatCurrency(item.OPERANDO, item.MOEDA)}</td>
              <td>
                {formatCurrency(item.TRANSACOES.reinvestimento, item.MOEDA)}
              </td>
              <td>{formatCurrency(item.RENDIMENTO, item.MOEDA)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ReportsBalance;
