import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React from "react";
import Report00002Pdf from "../../Templates/Reports/00002Pdf";

const Manager = () => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [enableSearchManager, setEnableSearchManager] = React.useState(false);
  const [responseReports, setResponseReports] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(false);

  const { data: dataSelectPeople, status: statusPeople } = useQuery(
    ["peoples", 1],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API}/v1/profile/people-by-role?role=50&expand=profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_ACCESS_TOKEN
              )}`
            }
          }
        )
        .then((res) => {
          return res.data.data;
        })
  );

  const getReport = (dataForm) => {
    const response = axios
      .get(
        `${
          process.env.REACT_APP_API
        }/v1/reports/manager?params=${JSON.stringify(dataForm)}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        }
      )
      .then((res) => {
        setLoadingData(true);
        setResponseReports(res.data);
        setLoadingData(false);
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const { mutate, isLoading } = useMutation(getReport, {
    onSuccess: (data) => {
      api.info({
        message: `Notificação`,
        description: "relatorio gerado com sucesso"
      });
    },
    onError: (error) => {
      console.log("error: ", error);
    }
  });

  const onFinish = (valuesInput) => {
    mutate(valuesInput);
  };

  const handleChange = (value) => {
    setSelectPeople(value);
  };

  const handleChangeAllManager = (checked) => {
    setEnableSearchManager(!checked);
    form.resetFields(["users"]);
  };

  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="center" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Gerente"
            >
              <Row className="my-25 text-center">
                <Col xs={24} xl={24} md={24}>
                  <Form
                    layout="vertical"
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Form.Item name="name" label="Nome">
                      <Input />
                    </Form.Item>

                    <Row>
                      <Col xs={4} xl={4} md={4}>
                        <Form.Item
                          name="allmanager"
                          label="Todos os Gerente"
                          width={800}
                        >
                          <Switch
                            defaultChecked
                            onChange={handleChangeAllManager}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={20} xl={20} md={20}>
                        {enableSearchManager ? (
                          <Form.Item name="users">
                            <Select
                              mode="multiple"
                              tagRender={(props) => {
                                const { label, value } = props;
                                return (
                                  <Tag color="cyan" key={value} closable={true}>
                                    {label}
                                  </Tag>
                                );
                              }}
                              tokenSeparators={[","]}
                              onChange={handleChange}
                              // options={(dataSelectPeople || []).map((d) => ({
                              //   value: d?.profile?.name,
                              //   label: d?.profile?.name
                              // }))}
                            >
                              {statusPeople === "success" &&
                                dataSelectPeople?.map((item) => (
                                  <>
                                    <Option
                                      key={item?.id}
                                      value={item?.user_id}
                                    >
                                      {item?.id} - {item?.profile?.name}
                                    </Option>
                                  </>
                                ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Form.Item>
                      <Button
                        loading={isLoading}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {responseReports ? (
          loadingData ? (
            <Spin />
          ) : (
            <Report00002Pdf
              title={responseReports?.title}
              data={responseReports?.user}
              amount={responseReports?.wallet}
            />
          )
        ) : (
          ""
        )}
      </Content>
    </div>
  );
};

export default Manager;
