import { Button, Card, Col, Divider, Flex, List, Modal, Table } from "antd";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNotificationsAdminFetchRequest } from "../../Hooks/notifications/useNotifications.query";
import Title from "antd/es/typography/Title";
// import { View } from "../../components/Icon";
// import {
//   notificationStatus,
//   typesNotificationList
// } from "../../helpers/typeNotification";
// import {
//   useNotificationFetchRequest,
//   useNotificationReadUpdateRequest
// } from "../../hooks/notification/useNotification.query";

const Notifications = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useNotificationsAdminFetchRequest();
  const notifications = data?.data.data;
  console.log("notifications", notifications);
  const [openModalNotification, setOpenModalNotification] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Para Quem?",
      dataIndex: "to_user",
      key: "to_user"
    },
    {
      title: "Quando",
      dataIndex: "data",
      key: "data"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    }
  ];
  // const listNotifications = notifications?.map((item) => {
  //   return {
  //     id: item.id,
  //     title: item.title,
  //     content: item.message,
  //     date: item.data,
  //     status: item.status,
  //     type: item.type
  //   };
  // });

  // const { mutateAsync } = useMutation(useNotificationReadUpdateRequest, {
  //   onSuccess: (data) => {
  //     queryClient.invalidateQueries("notification-notread");
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   }
  // });

  // const handleOpenNotification = (e) => {
  //   setOpenModalNotification(true);
  //   setModalContent(e);
  //   mutateAsync(e.id);
  // };
  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Notificações</Title>
        {/* <Button className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button> */}
      </Flex>
      <Divider />
      <Col>
        {/* <Button type="primary" className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button> */}
      </Col>

      <Card bordered={false} className="criclebox tablespace mb-24" title="">
        <Table
          columns={columns}
          dataSource={notifications}
          pagination={false}
          className="ant-border-space"
          bordered
        />
      </Card>
    </div>
  );
};

export default Notifications;
