import { useQuery } from "@tanstack/react-query";
import {
  Navigate,
  useNavigate,
  useRoutes,
  useLocation
} from "react-router-dom";
import Main from "../components/layout/Main";
import Batch from "../pages/Batch";
import Copy from "../pages/Copy";
import Home from "../pages/Home";
import Plans from "../pages/Planos";
import PlansCreate from "../pages/Planos/create";
import Reports from "../pages/Reports";
import Investor from "../pages/Reports/list";
import Manager from "../pages/Reports/manager";
import RetroEntry from "../pages/RetroEntry/entry";
import Role from "../pages/Role";
import SignIn from "../pages/SignIn";
import User from "../pages/User";
import Transactions from "../pages/User/transactions";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/create";
import EditUser from "../pages/Users/edit";
import Withdrawal from "../pages/Withdrawal";
import api from "../services/api";
import { isAuthenticated } from "../services/auth";
import List from "../pages/Reports/list";
import Offices from "../pages/Offices";
import Create from "../pages/Offices/create";
import Notifications from "../pages/Notifications";
import Entry from "../pages/Reports/entry";
import PersonalInformations from "../pages/User/personalInformations";
import ManagerAccount from "../pages/User/managerAccount";
import Gains from "../pages/User/gains";
import Documentation from "../pages/User/documentation";
import PersonalNotifications from "../pages/User/notifications";
import PersonalTransactions from "../pages/User/transactions";
import CreateNotification from "../pages/Notifications/create";
import NotificationCreate from "../pages/User/createNotification";
import Log from "../pages/Log";
import { useAccessFetchRequest } from "../Hooks/access/useAccess.query";
import Promotions from "../pages/Promotions";
import CreatePromotion from "../pages/Promotions/create";
import { BackgroundFetch, PageLoading } from "../components/Loading";
import ManagerGain from "../pages/Manager";
import Contracts from "../pages/Contracts";
import ViewContract from "../pages/Contracts/view";
import ViewOffice from "../pages/Offices/view";
import EditOffice from "../pages/Offices/edit";
import DetailsContract from "../pages/Contracts/details";
import ContractPdf from "../pages/Contracts/contract";
import EntryAll from "../pages/RetroEntry";
import PlanEdit from "../pages/Planos/edit";
import ReportsBalance from "../pages/Reports/balance";
import Investors from "../pages/User/investors";

export default function MainRouter() {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const page = pathname.split("/");
  pathname = pathname.replace("/", "");

  const { data, isLoading: loading } = useAccessFetchRequest();
  const access = data?.data?.access;

  if (pathname === "login" && data?.status === 200) {
    navigate("/painel");
  }

  return useRoutes([
    {
      path: "/",
      element: loading ? (
        <PageLoading />
      ) : isAuthenticated() ? (
        <Main />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/painel" /> },
        // { path: "/", element: <Home /> },
        { path: "/painel", element: <Home /> },
        access >= 50 && {
          path: "investidor",
          children: [
            { path: "", element: <Users /> },
            {
              path: "criar",
              element: loading ? (
                "loading"
              ) : access >= 99 ? (
                <CreateUser />
              ) : (
                "401"
              )
            },
            {
              path: "editar/:id",
              element: access >= 99 ? <EditUser /> : "401"
            },
            { path: "historico/:id", element: <Transactions /> },
            {
              path: "perfil/:id",
              element: <User />,
              children: [
                {
                  path: "informacoes-pessoais",
                  children: [
                    { path: "", element: <PersonalInformations /> },
                    { path: "editar", element: <EditUser /> }
                  ]
                },
                { path: "gerente", element: <ManagerAccount /> },
                { path: "conta", element: <Gains /> },
                { path: "documentos", element: <Documentation /> },
                { path: "carteira", element: <Investors /> },
                {
                  path: "notificacoes",
                  children: [
                    { path: "", element: <PersonalNotifications /> },
                    { path: "criar", element: <NotificationCreate /> }
                  ]
                },
                { path: "transacoes", element: <PersonalTransactions /> }
              ]
            }
          ]
        },
        access >= 99 && {
          path: "contratos",
          children: [
            { path: "", element: <Contracts /> },
            {
              path: ":id",
              element: <ViewContract />,
              children: [
                { path: "detalhes", element: <DetailsContract /> },
                { path: "contrato", element: <ContractPdf /> }
              ]
            }
          ]
        },
        access >= 99 && {
          path: "lancamentos",
          children: [
            {
              path: "",
              element: <EntryAll />,
              children: [
                { path: "", element: <Batch /> },
                { path: "clientes", element: <RetroEntry /> }
              ]
            }
          ]
        },
        access >= 99 && {
          path: "pagamento-gerente",
          children: [{ path: "", element: <ManagerGain /> }]
        },
        access >= 99 && {
          path: "promocoes",
          children: [
            { path: "", element: <Promotions /> },
            { path: "criar", element: <CreatePromotion /> }
          ]
        },
        access >= 99 && {
          path: "lote",
          children: [{ path: "", element: <Batch /> }]
        },
        access >= 99 && {
          path: "role",
          children: [{ path: "", element: <Role /> }]
        },
        access >= 99 && {
          path: "saque",
          children: [{ path: "", element: <Withdrawal /> }]
        },
        access >= 99 && {
          path: "log",
          children: [{ path: "", element: <Log /> }]
        },
        access >= 99 && {
          path: "planos",
          children: [
            { path: "", element: <Plans /> },
            { path: "criar", element: <PlansCreate /> },
            { path: ":id/editar", element: <PlanEdit /> }
          ]
        },
        access >= 99 && {
          path: "copy",
          children: [{ path: "", element: <Copy /> }]
        },
        access >= 99 && {
          path: "notificacao",
          children: [
            { path: "", element: <Notifications /> },
            { path: "criar", element: <CreateNotification /> }
          ]
        },
        access >= 99 && {
          path: "relatorios",
          children: [
            { path: "", element: <Reports /> },
            { path: "gerente", element: <Manager /> },
            { path: "lista", element: <List /> },
            { path: "entry", element: <Entry /> },
            { path: "balanco", element: <ReportsBalance /> }
          ]
        },
        access >= 99 && {
          path: "escritorios",
          children: [
            { path: "", element: <Offices /> },
            { path: "criar", element: <Create /> },
            { path: ":id", element: <ViewOffice /> },
            { path: ":id/editar", element: <EditOffice /> }
          ]
        }
      ]
    },
    { path: "/login", element: <SignIn /> },
    { path: "*", element: "404" }
  ]);
}
