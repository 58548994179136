import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserNotificationsAdminFetchRequest } from "../../Hooks/notifications/useNotifications.query";
import { Button, Divider, Flex, Table } from "antd";
import Title from "antd/es/typography/Title";

const PersonalNotifications = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useUserNotificationsAdminFetchRequest(id);
  const notifications = data?.data.data;
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Mensagem",
      dataIndex: "message",
      key: "message"
    },
    // {
    //   title: "Lida",
    //   dataIndex: "read_at",
    //   key: "read_at"
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Criado",
      dataIndex: "created_at",
      key: "created_at"
    }
  ];
  return (
    <>
      <Flex justify="space-between" align="center">
        <Title level={4}>Notificação</Title>
        <Button onClick={() => navigate(`criar`)}>Nova Notificação</Button>
      </Flex>
      <Divider />
      <div className="table-responsive">
        <Table dataSource={notifications} columns={columns} />
      </div>
    </>
  );
};

export default PersonalNotifications;
