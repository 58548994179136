import React, { Fragment } from "react";
import { typeFormat } from "../../helpers/formatType";
import { formatCurrency } from "../../helpers/moneyFormat";

function Report00002Pdf({ data, amount, title }) {
  return (
    <>
      <table width={"100%"}>
        <tr>
          <td>
            <h1>{title}</h1>
          </td>
        </tr>
      </table>

      {data?.map((item, index) => {
        return (
          <table
            border={0}
            width={"100%"}
            key={index}
            style={{ margin: "10px 0" }}
          >
            <tr style={{ backgroundColor: "#ccc", padding: 10, height: 40 }}>
              <th colSpan={2} width={"70%"}>
                {item?.profile?.name}
              </th>
              <th style={{ textAlign: "center" }}>
                {item.profile?.document} / {item.profile?.account_number}
              </th>
            </tr>
            <tr>
              <td>Email: {item.profile?.email}</td>
              <td>Gerente: {item.profile?.manager?.name}</td>
              <td>Moeda: {item.profile?.currency}</td>
            </tr>

            <tr
              style={{
                backgroundColor: "#ececec",
                margin: 10,
                height: 20,
                textAlign: "center"
              }}
            >
              <th colSpan={3}>Clientes</th>
            </tr>
            <tr>
              <td colSpan={5}>
                <table width={"100%"} border={0} className="table-transactions">
                  <tr className="table-transactions">
                    <th>Nome</th>
                    <th>Conta</th>
                    <th>Email</th>
                  </tr>
                  {item?.clients?.map((tr) => (
                    <Fragment key={tr.id}>
                      <tr>
                        <td>{tr.name}</td>
                        <td>{tr.account_number}</td>
                        <td>{tr.email}</td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <table width={"100%"} border={0}>
                            <tr>
                              <th>Data</th>
                              <th>Tipo</th>
                              <th>Percentual</th>
                              <th>Valor</th>
                              <th>Status</th>
                            </tr>
                            {tr?.transactions?.map((transaction) => (
                              <tr key={transaction.id}>
                                <td>{transaction.date}</td>
                                <td>
                                  {typeFormat(transaction.type_transaction)}
                                </td>
                                <td>{transaction.percent || "-"}</td>
                                <td>
                                  {formatCurrency(transaction.amount_money)}
                                  {transaction.base_calculation_percentage ? (
                                    <small>{` - calculo base: ${transaction.base_calculation_percentage}%`}</small>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{transaction.status || 0}</td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                      <tr
                        style={{
                          backgroundColor: "#ececec",
                          margin: 10,
                          height: 20,
                          textAlign: "center"
                        }}
                      >
                        <th colSpan={3}>Carteira</th>
                      </tr>
                      <tr>
                        <th>Valor</th>
                        <th>Sacado</th>
                        <th>Disponível</th>
                      </tr>
                      <tr>
                        <td>{tr?.wallet?.amount}</td>
                        <td>{tr?.wallet?.expense}</td>
                        <td>{tr?.wallet?.available_for_withdrawal}</td>
                      </tr>
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            height: "30px",
                            backgroundColor: "#f5f5f5"
                          }}
                        >
                          <hr />
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </table>
              </td>
            </tr>
          </table>
        );
      })}

      {amount ? (
        <>
          <br />
          <br />
          <table width={"100%"}>
            <tr
              style={{
                backgroundColor: "#ececec",
                margin: 10,
                height: 20,
                textAlign: "center"
              }}
            >
              <th colSpan={3}>Valores Totais</th>
            </tr>

            <tr>
              <td>Valores Carteira</td>
              <td>Valores Saida</td>
              <td>Valores Disponivel para saque</td>
            </tr>

            <tr>
              {amount?.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <td>{formatCurrency(item)}</td>
                  </Fragment>
                );
              })}
            </tr>
          </table>
        </>
      ) : (
        ""
      )}
    </>
  );
}
export default Report00002Pdf;
