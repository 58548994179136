import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Statistic,
  notification
} from "antd";
import React, { useState } from "react";
import { formatCurrency } from "../../helpers/moneyFormat";
import { useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import Title from "antd/es/typography/Title";
import ReactApexChart from "react-apexcharts";
import MyModal from "../../components/MyModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useTransactionPostRequest,
  useTransactionTotalWithdraw,
  useWithdrawBeyondPostRequest
} from "../../Hooks/transactions/useTransactions.query";

const Gains = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idProfile, setIdProfile] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [withdraw, setWithdraw] = useState({ title: "", show: false });
  const [withdrawBeyond, setWithdrawBeyond] = useState({
    title: "",
    show: false
  });
  const [withdrawTotal, setWithdrawTotal] = useState({
    title: "",
    show: false
  });
  const [reinvestment, setReinvestment] = useState({ title: "", show: false });

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const arrayEntrada22 = investor?.transactions?.map((transaction) => ({
    amount: transaction?.amount_money,
    month: transaction?.month_year,
    type: transaction?.type_transaction,
    percent: transaction?.percent
  }));
  const dadosAgrupados222 = arrayEntrada22?.reduce(
    (agrupados, dado) => {
      const { amount, month, type, percent } = dado;
      const valor = parseFloat(amount);

      if (month && !isNaN(valor)) {
        if (type === 1) {
          if (agrupados.percent[month]) {
            agrupados.percent[month] += percent;
          } else {
            agrupados.percent[month] = percent;
          }
        } else {
          if (agrupados.ganhos[month]) {
            agrupados.ganhos[month] += valor;
          } else {
            agrupados.ganhos[month] = valor;
          }
        }
      }

      return agrupados;
    },
    { percent: {}, ganhos: {} }
  );
  const arrayResultadoGanhos = Object.keys(dadosAgrupados222?.ganhos || {}).map(
    (data) => ({
      amount: dadosAgrupados222.ganhos[data],
      mes: data
    })
  );
  const arrayResultadoPercent = Object.keys(
    dadosAgrupados222?.percent || {}
  ).map((data) => ({
    amount: dadosAgrupados222.percent[data],
    mes: data
  }));

  const narrXPercent = arrayResultadoPercent?.map((a) => a.mes) || [];
  const narrDataGanhos = arrayResultadoGanhos?.map((a) => a.amount) || [];
  const narrDataPercent = arrayResultadoPercent?.map((a) => a.amount) || [];
  const narrXGanhos = arrayResultadoGanhos?.map((a) => a.mes) || [];
  const chart = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2
          // dataLabels: {
          //   position: "top"
          // }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return formatCurrency(val);
        },
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#eee"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXGanhos
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      },
      title: {
        text: "Ganho Mensal",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          }
        }
      }
    },

    series: [
      {
        name: "Valor fechamento Mensal",
        data: narrDataGanhos,
        color: "#1890ff"
      }
    ]
  };
  const chart2 = {
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val} %`;
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#555"],
          transform: "rotate(90)"
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#1890aa"]
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#900"]
        }
      },
      xaxis: {
        categories: narrXPercent,
        position: "top",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          align: "right",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ["#000"]
          },
          formatter: function (val) {
            return `${val} %`;
          }
        }
      },
      title: {
        text: "Porcentagem mes",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return `${val} %`;
          }
        }
      }
    },

    series: [
      {
        name: "Porcentagem mensal",
        data: narrDataPercent,
        color: "#1890ff"
      }
    ]
  };

  const { mutateAsync, isLoading: sendingApport } = useMutation(
    useTransactionPostRequest,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401) {
          api.error({
            message: "Error",
            description: data.data.message
          });
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );
  const { mutateAsync: mutateTotal, isLoading: sendingTotal } = useMutation(
    useTransactionTotalWithdraw,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401) {
          api.error({
            message: "Error",
            description: data.data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );
  const { mutateAsync: mutateBeyond, isLoading: sendingBeyond } = useMutation(
    useWithdrawBeyondPostRequest,
    {
      onSuccess: (data) => {
        setIsModalVisible(false);
        setAport({ title: "", show: false });
        form.resetFields();
        queryClient.invalidateQueries("adminInvestor");
        if (data.data.status === 401 || data.data.status === 400) {
          api.error({
            message: "Error",
            description: data.data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
      }
    }
  );

  const onFinish = (values) => {
    mutateAsync(values);
  };
  const onFinishWithdrawTotal = (values) => {
    mutateTotal(values);
  };
  const onFinishWithdrawBeyond = (values) => {
    mutateBeyond(values);
  };

  const handleClickAport = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setAport({ title: "Aportar Valor", show: true });
  };
  const handleClickEntry = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setEntry({ title: "% de ganho mês", show: true });
  };
  const handleClickReinvestment = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setReinvestment({ title: "Reinvestimento", show: true });
  };
  const handleClickWithdraw = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdraw({ title: "Sacar valores", show: true });
  };
  const handleClickWithdrawTotal = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawTotal({ title: "Sacar valor total", show: true });
  };
  const handleClickWithdrawBeyond = (e) => {
    setIdProfile(e);
    setIsModalVisible(true);
    setWithdrawBeyond({ title: "Sacar valor parcial", show: true });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAport({ title: "", show: false });
    setEntry({ title: "", show: false });
    setWithdraw({ title: "", show: false });
    setReinvestment({ title: "", show: false });
    setWithdrawBeyond({ title: "", show: false });
    setWithdrawTotal({ title: "", show: false });
  };

  return (
    <>
      {contextHolder}
      <Title level={4}>Ganhos</Title>
      <Divider />
      <Flex gap="small" wrap="wrap">
        <Button onClick={() => handleClickAport(investor.user_id)}>
          Aporte
        </Button>
        <Button onClick={() => handleClickEntry(investor.user_id)}>
          Rentabilidade
        </Button>
        <Button onClick={() => handleClickReinvestment(dataInvestor.user_id)}>
          Reinvestimento
        </Button>
        <Button onClick={() => handleClickWithdraw(investor.user_id)}>
          Sacar
        </Button>
        <Button onClick={() => handleClickWithdrawTotal(dataInvestor.user_id)}>
          Saque valor total
        </Button>
        <Button onClick={() => handleClickWithdrawBeyond(dataInvestor.user_id)}>
          Saque acima rentabilidade
        </Button>
      </Flex>
      <Divider />
      <Row gutter={10}>
        <Col md={12} sm={24}>
          <Card bordered={true}>
            <Statistic
              title="Valor na carteira"
              value={formatCurrency(
                investor?.wallet?.amount,
                investor.currency
              )}
              valueStyle={{
                color: "#3f8600"
              }}
            />
          </Card>
        </Col>
        <Col md={12} sm={24}>
          <Card bordered={true}>
            <Statistic
              title="Valor ja sacado"
              value={formatCurrency(
                investor?.wallet?.expense,
                investor.currency
              )}
              valueStyle={{
                color: "#cf1322"
              }}
            />
          </Card>
        </Col>
      </Row>

      <br />
      <Row gutter={[10]}>
        <Col span={24} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart.options}
              series={chart.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col>
        <Col span={24} md={12} className="mb-24 ">
          <Card bordered={false} className="criclebox h-full">
            <ReactApexChart
              className=""
              options={chart2.options}
              series={chart2.series}
              type="bar"
              height={220}
            />
          </Card>
        </Col>
      </Row>

      <MyModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        onOk={onFinish}
        title={
          <h2 style={{ textAlign: "center" }}>
            {aport?.title ||
              entry?.title ||
              withdraw?.title ||
              reinvestment?.title}
          </h2>
        }
        okButtonProps={{
          hidden: true
        }}
        cancelButtonProps={{
          disabled: true
        }}
      >
        <Form
          preserve={false}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          fields={[
            {
              name: ["investor"],
              value: investor?.user_id
            }
          ]}
          form={form}
        >
          {aport?.show && (
            <Col>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="amount">
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="2" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {entry?.show && (
            <Col>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="percentage">
                    <Input prefix="%" />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="1" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {withdraw?.show && (
            <Col>
              <Row wrap={false} gutter={[16, 16]}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor disponível para saque">
                      {formatCurrency(
                        investor?.wallet?.available_for_withdrawal,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <br />
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item
                    name="amount"
                    initialValue={investor?.wallet?.available_for_withdrawal}
                  >
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="5" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}

          {reinvestment?.show && (
            <>
              <Col>
                <Row wrap={false} gutter={[16, 16]}>
                  <Col flex="auto">
                    <Descriptions title="" layout="vertical" bordered>
                      <Descriptions.Item label="Valor a ser reinvestido">
                        {formatCurrency(
                          investor?.wallet?.available_for_withdrawal,
                          investor?.currency
                        )}
                        <Form.Item
                          name="amount"
                          initialValue={
                            investor?.wallet?.available_for_withdrawal
                          }
                          hidden
                        >
                          <Input prefix="" />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row wrap={false} hidden>
                  <Col flex="auto">
                    <Form.Item name="investor" hidden>
                      <Input prefix="" />
                    </Form.Item>
                    <Form.Item name="type" initialValue="8" hidden>
                      <Input prefix="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <br />
            </>
          )}

          {withdrawTotal?.show || withdrawBeyond?.show ? (
            ""
          ) : (
            <Col flex="auto">
              <Form.Item>
                <Button
                  loading={sendingApport}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Salvar
                </Button>
              </Form.Item>
            </Col>
          )}
        </Form>

        {withdrawTotal?.show && (
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinishWithdrawTotal}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: investor?.user_id
              }
            ]}
            form={form}
          >
            <Col style={{ marginBottom: 16 }}>
              <Row wrap={false}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor total a ser retirado">
                      {formatCurrency(
                        investor?.wallet?.amount,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row wrap={false} hidden>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                  <Form.Item name="type" initialValue="99" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col flex="auto">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Sacar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
        {withdrawBeyond?.show && (
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinishWithdrawBeyond}
            autoComplete="off"
            fields={[
              {
                name: ["investor"],
                value: investor?.user_id
              }
            ]}
            form={form}
          >
            <Col style={{ marginBottom: 16 }}>
              <Row wrap={false}>
                <Col flex="auto">
                  <Descriptions title="" layout="vertical" bordered>
                    <Descriptions.Item label="Valor disponível">
                      {formatCurrency(
                        investor?.wallet?.amount,
                        investor?.currency
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="amount">
                    <Input prefix={investor?.currency} />
                  </Form.Item>
                </Col>
              </Row>
              <Row wrap={false}>
                <Col flex="auto">
                  <Form.Item name="investor" hidden>
                    <Input prefix="" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col flex="auto">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Sacar
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </MyModal>
    </>
  );
};

export default Gains;
