import {
  CheckOutlined,
  DollarCircleOutlined,
  LinkOutlined,
  PercentageOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Table,
  Tag
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link, NavLink } from "react-router-dom";
import MyModal from "../../components/MyModal";
import { formatStatus } from "../../helpers/formatStatus";
import { convertTimestampToDate } from "./../../helpers/convertTimestampToDate";
import { typeAccess } from "../../helpers/access";

const Users = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState(null);
  const [aport, setAport] = useState({ title: "", show: false });
  const [entry, setEntry] = useState({ title: "", show: false });
  const [entryDate, setEntryDate] = useState(null);

  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close
    }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  });

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => text.name,
      width: "40%",
      ...getColumnSearchProps("name")
    },
    {
      title: <LinkOutlined />,
      dataIndex: "link",
      key: "link",
      render: (_, text) => (
        <Link to={`perfil/${text.key}/informacoes-pessoais`}>
          <LinkOutlined />
        </Link>
      ),
      width: "5%"
    },
    {
      title: "Plano",
      dataIndex: "plan",
      key: "plan",
      width: "15%"
    },
    {
      title: "Data Contrato",
      dataIndex: "started_at",
      key: "started_at",
      width: "15%",
      render: (_) => (
        <p>
          <span>
            <small>Cadastrado em: </small>
            {convertTimestampToDate(_.created_at)}
          </span>{" "}
          <br />
          <span>
            <small>Inicio do contrato: </small>
            {_.start_of_contract}
          </span>
        </p>
      )
    },
    {
      title: "Accesso",
      key: "access",
      dataIndex: "access",
      render: (text) => (
        <>
          <p>{typeAccess(text)}</p>
        </>
      )
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = "gray";
            if (tag === "Ativo") color = "green";
            if (tag === "Bloqueado") color = "volcano";
            if (tag === "Não Ativo") color = "gray";

            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      width: "15%"
    },
    {
      title: "Sub-Plano",
      key: "subplan",
      dataIndex: "subplan",
      render: (_, text) => <p>{text.subplan ? "Sim" : "Não"}</p>
    }
    // {
    //   title: (
    //     <Tooltip placement="top" title="Lançar % de ganhos do mês">
    //       <PercentageOutlined style={{ fontSize: "17px" }} />
    //     </Tooltip>
    //   ),
    //   key: "entry",
    //   dataIndex: "entry",
    // },
    // {
    //   title: (
    //     <Tooltip placement="top" title="Investir novo Valor">
    //       <DollarCircleOutlined style={{ fontSize: "17px" }} />
    //     </Tooltip>
    //   ),
    //   key: "contributed",
    //   dataIndex: "contributed",
    // },
    // {
    //   title: "Bloquear",
    //   key: "block",
    //   dataIndex: "block"
    // }
    // {
    //   title: "Liberar saque",
    //   key: "withdrawal",
    //   dataIndex: "withdrawal"
    // }
    // {
    //   title: "Action",
    //   key: "action",
    //   dataIndex: "action",
    //   width: "15%",
    // },
  ];
  // search

  const { data: dataInvestors, isLoading } = useQuery(["investidores", 1], () =>
    axios
      .get(`${process.env.REACT_APP_API}/v1/profile?expands=user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        return res.data.data;
      })
  );

  const put = async (dataForm) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/entry/create-retro`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const { mutate } = useMutation(put, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
    }
  });

  const onFinish = (values) => {
    const entry = {
      amount: values.amount,
      percent: values.percent,
      investor: values.id,
      entryDate,
      type: values.type
    };
    mutate(entry);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAport({ title: "", show: false });
    setEntry({ title: "", show: false });
  };

  // const handleClickAport = (e) => {
  //   setId(e)
  //   setIsModalVisible(true)
  //   setAport({ title: 'Aportar Valor', show: true })
  // }

  const handleClickEntry = (e) => {
    setId(e);
    setIsModalVisible(true);
    setEntry({ title: "% de ganho mês", show: true });
  };

  useEffect(() => {
    setEntryDate(dayjs(new Date()).format("YYYY-MM-DD"));
  }, []);

  const dataUser = dataInvestors?.map((profile, index) => {
    return {
      key: profile?.id,
      name: profile?.name,
      plan: profile?.myPlan?.plan,
      started_at: profile,
      subplan: profile?.subplan,
      access: profile?.user?.access_given,
      // aport: (
      //   <Link>
      //     <Badge
      //       className="site-badge-count-109"
      //       count={profile.contributed.length === 0 ? "$" : <CheckOutlined />}
      //       onClick={
      //         profile.contributed.length === 0
      //           ? () => handleClickAport(profile.user_id)
      //           : ""
      //       }
      //       style={{
      //         backgroundColor:
      //           profile.contributed.length === 0 ? "#52c41a" : "transparent",
      //         color: profile.contributed.length === 0 ? "#fefefe" : "#555"
      //       }}
      //     />
      //   </Link>
      // ),
      status: [formatStatus(profile?.user?.status)],
      contributed: (
        <DollarCircleOutlined
          onClick={() => handleClickEntry(profile.user_id)}
        />
      ),
      entry: (
        <PercentageOutlined onClick={() => handleClickEntry(profile.user_id)} />
      ),
      action: (
        <Space size="middle">
          <Link>Visualizar</Link>
          <Link to={`/painel/investidor/editar/${profile.id}`}>Editar</Link>
          <Link>Delete</Link>
        </Space>
      ),
      block: "",
      withdrawal: <Switch uncheckedText="0" checkedText="1" />
    };
  });

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Button className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Flex>
      <Divider />
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Investidores"
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={dataUser}
                pagination={true}
                className="ant-border-space custom-row-hover"
                bordered
              />
            </div>
          </Card>
        </Col>
      </Row>

      <MyModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        title={aport.title || entry.title}
      >
        {aport.show && (
          <Form
            preserve={false}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            fields={[
              {
                name: ["id"],
                value: id
              },
              {
                name: ["type"],
                value: 2
              },
              {
                name: "created",
                value: dayjs(new Date())
              }
            ]}
            form={form}
          >
            <Row gutter={[24, 1]}>
              <Form.Item name="amount">
                <Input prefix="R$" />
              </Form.Item>

              <Form.Item name="id" hidden>
                <Input prefix="" />
              </Form.Item>

              <Form.Item label="tipo" name="type" hidden>
                <Input prefix="" />
              </Form.Item>
              <Form.Item label="tipo" name="created" hidden>
                <DatePicker disabled />
              </Form.Item>
            </Row>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
        {/* % */}
        {entry.show && (
          <Row gutter={[24, 5]}>
            <Form
              preserve={false}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              fields={[
                {
                  name: ["id"],
                  value: id
                },
                {
                  name: ["type"],
                  value: 1
                },
                {
                  name: "created",
                  value: dayjs(new Date())
                }
              ]}
              form={form}
            >
              <Form.Item name="percent">
                <Input prefix="%" />
              </Form.Item>
              <Form.Item name="id" hidden>
                <Input prefix="" />
              </Form.Item>
              <Form.Item label="tipo" name="type" hidden>
                <Input prefix="" />
              </Form.Item>
              <Form.Item label="tipo" name="created" hidden>
                <DatePicker disabled picker="month" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Row>
        )}
      </MyModal>
    </div>
  );
};

export default Users;
