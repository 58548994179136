import {
  CheckOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
  MinusCircleOutlined
} from "@ant-design/icons";

const status = {
  0: "Bloqueado",
  1: "Não Ativo",
  10: "Ativo"
};

const statusZap = {
  signed: <CheckOutlined style={{ color: "#445447" }} />,
  pending: <LoadingOutlined rotate={180} style={{ color: "#544f44" }} />,
  new: <MinusCircleOutlined style={{ color: "#544f44" }} />,
  "link-opened": <ClockCircleOutlined style={{ color: "#544f44" }} />
};
const colorStatusZap = {
  signed: "#1ccd3a",
  pending: "#cd641c",
  new: "#1cc4cd",
  "link-opened": "#b8cd1c"
};

export const formatStatus = (type) => {
  return status[type] || "";
};

export const formatStatusZapSign = (type) => {
  return statusZap[type] || "";
};

export const formatColorZapSign = (type) => {
  return colorStatusZap[type] || "";
};
