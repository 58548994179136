import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Table,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React from "react";
import { useEntryFetchRequest } from "../../Hooks/reports/useReports.query";
import { LoadingOutlined } from "@ant-design/icons";
const EditableContext = React.createContext(null);

var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();

console.log(`${yyyy}-${String(today.getMonth()).padStart(2, "0")}-30`);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef(null);
  const form = React.useContext(EditableContext);
  React.useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    // form.resetFields([dataIndex]);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    } catch (error) {
      console.log("save Failed:", error);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          width: 100
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`
          }
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const Entry = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const put = async (dataForm) => {
    console.log("dataForm,", dataForm);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/create`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const { mutate, isLoading } = useMutation(put, {
    onSuccess: (data) => {
      // setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("entryreports");
      if (data.status === 400) {
        api.error({
          message: `Error: [${data.status}]`,
          description: data.message
        });
        return;
      }
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message
      });
    }
  });

  const { data: entryData, isLoading: loadingEntry } = useEntryFetchRequest();
  const searchEntry = entryData?.data;

  const defaultColumns = [
    {
      title: "Nome",
      dataIndex: "profile",
      key: "profile",
      render: (text) => (
        <p>
          {text?.name} ({text?.email})
        </p>
      )
    },
    {
      title: "Plano",
      dataIndex: "profile",
      key: "profile",
      render: (text) => <p>{text?.myPlan?.plan || ""}</p>
    },
    {
      title: "Data",
      dataIndex: "transactions",
      key: "transactions",
      render: (_, text) => (
        <>
          {/* {console.log("data", text)} */}
          <p>{text?.transactions?.date || ""}</p>
        </>
      )
    },
    {
      title: "Rentabilidade",
      dataIndex: "transactions",
      key: "transactions",
      render: (text, _) => (
        <>
          {_?.profile?.transactions?.month_year === mm + "/" + yyyy ? (
            <p>{text?.percent} %</p>
          ) : _?.profile?.subplan == 1 ? (
            <p>editar</p>
          ) : (
            <p>{text?.percent} %</p>
          )}
        </>
      ),
      editable: true
    },
    {
      text: "Valor",
      dataIndex: "transactions",
      key: "transactions",
      render: (text) => (
        <p>
          {/* {console.log("_", text)} */}
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            text?.amount_money
          )}
        </p>
      )
    }
  ];

  const handleSave = (row) => {
    const data = {
      investor: row.id,
      percentage: row.transactions,
      type: 1,
      register: `${yyyy}-${String(today.getMonth()).padStart(2, "0")}-30`
      // register: "2024-06-30"
    };

    mutate(data);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="center" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Relatórios"
            >
              {/* <Row className="my-25 text-center">
                <Button
                  onClick={handleGenerate}
                  type="primary"
                  size="large"
                  block
                  // loading={loadingSearch}
                >
                  GERAR
                </Button>
              </Row> */}

              <Table
                loading={loadingEntry}
                components={components}
                rowClassName={() => "editable-row"}
                bordered
                dataSource={searchEntry}
                columns={columns}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default Entry;
