import React, { useEffect, useState } from "react";
import { Typography } from "antd";
const { Title } = Typography;

const motivationalQuotes = [
  "Bom dia! Que suas análises sejam afiadas e seus investimentos prosperem hoje.",
  "Seja bem-vindo ao nosso painel financeiro! Vamos tornar cada dia um passo mais próximo de nossos objetivos.",
  "Comece o dia com determinação e termine com satisfação. Bem-vindo ao sucesso financeiro!",
  "Bom dia! Lembre-se, cada número conta. Vamos fazer cada um deles contar para nosso sucesso.",
  "Dê as boas-vindas ao novo dia com entusiasmo e foco. Estamos prontos para conquistar os mercados!",
  "Bom dia! Hoje é uma nova oportunidade para alcançar nossas metas financeiras. Vamos com tudo!",
  "Que sua jornada financeira comece com positividade e termine com realizações. Boas-vindas ao nosso dia de sucesso!",
  "Bem-vindo ao painel onde cada gráfico é uma oportunidade e cada dado nos leva mais perto de nossos objetivos. Bom dia!",
  "Bom dia! Que suas estratégias sejam sólidas e suas decisões sejam lucrativas hoje.",
  "Comece o dia com confiança e determinação. Estamos prontos para enfrentar os desafios financeiros que vierem pela frente!",
  "Que suas decisões financeiras sejam sábias e seus investimentos frutíferos hoje.",
  "Bem-vindo ao painel onde cada gráfico é uma chance de prosperidade. Vamos aproveitar cada uma!",
  "Comece o dia focado e determinado. O sucesso financeiro está ao seu alcance!",
  "Aproveite o dia para transformar desafios em oportunidades no mercado financeiro.",
  "Cada dia é uma nova chance de conquistar nossas metas financeiras. Vamos com tudo!",
  "Que suas estratégias hoje sejam tão sólidas quanto suas convicções financeiras. Boa sorte!",
  "O segredo para o sucesso financeiro é persistência. Vamos continuar focados e determinados!",
  "Bom dia! Que suas análises sejam detalhadas e suas decisões sejam lucrativas.",
  "Hoje é um novo dia para explorar oportunidades e maximizar seus investimentos.",
  "Encare cada desafio financeiro como uma chance de aprendizado e crescimento.",
  "Cada número tem uma história a contar. Vamos descobrir as histórias de sucesso hoje!",
  "Bem-vindo ao mundo financeiro, onde cada decisão pode levar à liberdade econômica.",
  "Que suas metas financeiras sejam alcançadas com disciplina e dedicação hoje.",
  "Um dia produtivo começa com um plano sólido e uma execução precisa. Vamos fazer isso!",
  "A chave para o sucesso financeiro é o planejamento e a ação constante. Vamos alcançar nossas metas!"
];

const DailyMotivationalQuote = () => {
  const [dailyQuote, setDailyQuote] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const index = currentDate.getDate() % motivationalQuotes.length;
    setDailyQuote(motivationalQuotes[index]);
  }, []);

  return (
    <Title level={5} style={{ color: "#efefef" }}>
      {dailyQuote}
    </Title>
  );
};

export default DailyMotivationalQuote;
