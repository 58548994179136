const types = {
  1: '%',
  2: 'Novo Investimento',
  3: 'Bonificaçoes',
  4: 'Calculo novo investimento',
  5: 'Saque',
  6: 'Calculo após saque',
  7: 'Calculo após bonificação',
}

export const typeFormat = (type) => {
  return types[type] || ''
}
