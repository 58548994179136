import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Flex, Form, Input, Select, notification } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useNotificationPost } from "../../Hooks/notifications/useNotifications.query";
import Title from "antd/es/typography/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};
const { Option } = Select;

const NotificationCreate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const { mutate, isLoading: sendingNotification } = useMutation(
    useNotificationPost,
    {
      onSuccess: (data) => {
        api.success({
          message: "Sucesso",
          description: data.data.message
        });
        queryClient.invalidateQueries("admin-notifications");
      }
    }
  );
  const onFinish = (dataSender) => {
    mutate(dataSender);
  };

  return (
    <>
      <Flex justify="space-between" align="center">
        <Button onClick={() => navigate(-1)}>
          <ArrowLeftOutlined />
        </Button>
      </Flex>
      <Flex justify="space-between" align="center">
        <Title level={4}>Enviar</Title>
      </Flex>
      <Divider />
      {contextHolder}
      <Form
        {...layout}
        layout="horizontal"
        form={form}
        name="create-user"
        onFinish={onFinish}
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
        initialValues={{ to_user: investor?.user_id }}
      >
        <Form.Item name="Title" label="Titulo" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="message" label="Mensagem" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label="Tipo"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select placeholder="Selecione o tipo de notificação" allowClear>
            <Option value="info">Informação</Option>
            <Option value="alert">Atençao</Option>
            <Option value="success">Sucesso</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="to_user"
          label="Para"
          rules={[{ required: true }]}
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            loading={!!sendingNotification}
            style={{ width: "100%", marginRight: 0 }}
            type="primary"
            htmlType="submit"
          >
            CRIAR
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default NotificationCreate;
