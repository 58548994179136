import React from "react";
import Lottie from "react-lottie";
import animationData from "../Lotties/candlestickloading";
const PageLoading = () => {
  return (
    <div
      style={{
        background: "#aeaeae52",
        width: "100%",
        height: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        backdropFilter: "blur(7px)"
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
          }
        }}
        height={200}
        width={200}
        style={{
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%"
        }}
      />
    </div>
  );
};

export default PageLoading;
