import { useMutation, useQuery } from "@tanstack/react-query";
import { Card, Col, Radio, Row, Table, notification } from "antd";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { useAccessFetchRequest } from "../../Hooks/access/useAccess.query";

// import { Container } from './styles';

function Role() {
  const [api, contextHolder] = notification.useNotification();
  const { data: accessGiven } = useAccessFetchRequest();
  const { data: dataInvestors, isLoading } = useQuery(
    ["investidores-role"],
    async () =>
      await axios
        .get(`${process.env.REACT_APP_API}/v1/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        })
        .then((res) => {
          return res.data.data;
        }),
    {
      enabled: !!accessGiven
    }
  );
  console.log("accessGiven", accessGiven);
  const dataUser = dataInvestors?.map((profile) => {
    return {
      id: profile.id,
      key: profile.user_id,
      name: profile.name,
      func: profile?.user?.access_given,
      access: profile?.user?.access_given
    };
  });
  const putRole = async (dataForm) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/user/update-role`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );

    return response.data;
  };
  const { mutate } = useMutation(putRole, {
    onSuccess: (data) => {
      api.success({
        message: "Sucesso",
        description: "Alterado com sucesso."
      });
    }
  });

  const onChange = (event) => {
    // Separa a string em um array de objetos
    const values = event.target.value.split(",").map((val) => {
      // Separa a chave e o valor em um objeto
      const keyValue = val.split(":");
      return { [keyValue[0]]: keyValue[1] };
    });

    // Cria um objeto combinando todos os objetos no array
    const obj = values.reduce((acc, val) => ({ ...acc, ...val }), {});

    // Exibe o objeto no console
    const user = {
      access_given: obj.role,
      id: obj.id
    };
    mutate(user);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => (
        <Link to={`/investidor/perfil/${text.id}`}>{text.name}</Link>
      ),
      width: "40%"
    },
    {
      title: "Função",
      key: "access",
      dataIndex: "access",
      render: (_, { access, key }) =>
        accessGiven?.data.access === 99 && access !== 99 ? (
          <>
            <Radio.Group
              defaultValue={`role:${access},id:${key}`}
              size="large"
              onChange={onChange}
            >
              <Radio.Button value={`role:99,id:${key}`}>Admin</Radio.Button>
              <Radio.Button value={`role:50,id:${key}`}>Gerente</Radio.Button>
              <Radio.Button value={`role:20,id:${key}`}>
                Gerente Banco
              </Radio.Button>
              <Radio.Button value={`role:5,id:${key}`}>
                Colaborador
              </Radio.Button>
              <Radio.Button value={`role:1,id:${key}`}>Investidor</Radio.Button>
            </Radio.Group>
          </>
        ) : (
          ""
        ),
      width: "15%"
    }
  ];

  return (
    <>
      <Row gutter={[24, 5]}>
        {contextHolder}
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Investidores"
          >
            <div className="table-responsive">
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={dataUser}
                pagination={false}
                className="ant-border-space"
                bordered
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Role;
