import React from "react";
import { useLogRequest } from "../../Hooks/log/useLog.query";
import { Card, Table, message } from "antd";
import { convertTimestampToDate } from "../../helpers/convertTimestampToDate";

const Log = () => {
  const { data } = useLogRequest();
  const logData = data?.data;

  const columns = [
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type"
    },
    {
      title: "Hora/Tempo de execução",
      dataIndex: "hour",
      key: "hour"
    },
    {
      title: "Ip",
      dataIndex: "ip",
      key: "ip"
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action"
    },
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        return <p>{convertTimestampToDate(text)}</p>;
      }
    }
  ];
  const col = logData?.map((item) => {
    return {
      key: item.id,
      type: item.type,
      hour: item.hour,
      ip: item.ip,
      action: item.action,
      created_at: item.created_at,
      message: item.message
    };
  });

  return (
    <div className="layout-content">
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Notificações"
      >
        <Table
          columns={columns}
          className="ant-border-space"
          bordered
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0
                }}
              >
                {record.message}
              </p>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable"
          }}
          dataSource={col}
        />
      </Card>
    </div>
  );
};

export default Log;
