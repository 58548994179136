import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ConsoleSqlOutlined
} from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTransactionPostRequest } from "../../Hooks/withdrawal/useWithdrawal.query";
import {
  formatCurrency,
  parseValueFloat,
  removeCaracter
} from "../../helpers/moneyFormat";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const date = new Date();
const startDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  2,
  "0"
)}-01`;
const endDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  2,
  "0"
)}-05`;

function Withdrawal() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isOpenModalReport, setIsOpenModalReport] = useState(false);
  const [isLoadingReaport, setIsLoadingReaport] = useState(false);
  const [dataReaport, setDataReaport] = useState([]);
  const [datetSearchWithdrawal, setDatetSearchWithdrawal] = useState({
    startDate,
    endDate
  });

  const { register, getValues, setValue, watch } = useForm();

  const { mutateAsync } = useMutation(useTransactionPostRequest, {
    onSettled: () => {
      console.log("onSettled");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("saque");
    },
    onError: () => {}
  });
  const { data, isLoading, refetch } = useQuery(["saque"], () =>
    axios
      .get(
        `${process.env.REACT_APP_API}/v1/withdrawal/requested-withdrawal?startDate=${datetSearchWithdrawal.startDate}&endDate=${datetSearchWithdrawal.endDate}&expand=profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        }
      )
      .then((res) => {
        return res.data.data;
      })
  );

  const handleAcceptWithdraw = (data) => {
    let amount = data.value;
    amount = amount.replace("$", "");
    const entry = {
      type: 5,
      amount,
      investor: data.accepted,
      accepted: 1,
      id_withdrawal: data.key
    };
    mutateAsync(entry);
  };
  const columns = [
    {
      title: "",
      dataIndex: "isAccepted",
      key: "isAccepted",
      render: (data) => {
        if (data) {
          return (
            <span alt="Ja repassado" title="Ja repassado">
              <CheckCircleOutlined />
            </span>
          );
        }
        return (
          <span title="Não repassado">
            <CloseCircleOutlined />
          </span>
        );
      },
      width: 5,
      rowScope: "row",
      fixed: "left"
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => (
        <Link to={`/investidor/perfil/${text.id}`}>{text.name}</Link>
      )
      // width: "40%",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value"
      // width: "15%",
    },
    {
      title: "Limite",
      dataIndex: "limit",
      key: "limit"
      // width: "15%",
    },
    // {
    //   title: "Saldo apos Saque",
    //   dataIndex: "after_withdraw",
    //   key: "after_withdraw",
    //   render: (_) => (
    //     <span>{formatCurrency((_.limit - _.amount))}</span>
    //   )
    // },
    {
      title: "Data solicitação",
      dataIndex: "started_at",
      key: "started_at"
      // width: "15%",
    },
    {
      title: "Aceitar solicitação",
      dataIndex: "accepted",
      key: "accepted",
      render: (_, data) => {
        if (data.isAccepted) {
          return <span></span>;
        }
        return (
          <span>
            <Link onClick={() => handleAcceptWithdraw(data)}>Aceitar</Link>
          </span>
        );
      }
      // width: "15%",
    }
  ];
  const dataUser = data?.map((profile, index) => {
    const options = {
      dateStyle: "full" || "long" || "medium" || "short",
      timeStyle: "full" || "long" || "medium" || "short"
    };
    return {
      key: profile?.id,
      id: profile?.profile?.id,
      name: profile?.profile?.name,
      value: formatCurrency(profile?.amount),
      limit: formatCurrency(profile?.limit),
      after_withdraw: profile,
      started_at: new Date(profile?.created_at * 1000).toLocaleString(
        "pt-br",
        options
      ),
      accepted: profile.user_id,
      isAccepted: profile.accepted
    };
  });

  let totalUSD = 0;
  let totalBRL = 0;
  dataUser?.forEach((item) => {
    const value = parseValueFloat(item.value);
    const currency = item.after_withdraw.profile.currency;

    if (currency === "USD") {
      totalUSD += value;
    } else if (currency === "BRL") {
      totalBRL += value;
    }
  });

  console.log("totalUSD", totalUSD);
  console.log("totalBRL", totalBRL);

  const searchBetweenDateWithdraw = (e) => {
    const rangeValue = e["range_search"];

    if (
      !rangeValue[0].format("YYYY-MM-DD") &&
      !rangeValue[1].format("YYYY-MM-DD")
    ) {
      setDatetSearchWithdrawal({
        startDate,
        endDate
      });
      return;
    }
    setDatetSearchWithdrawal({
      startDate: rangeValue[0].format("YYYY-MM-DD"),
      endDate: rangeValue[1].format("YYYY-MM-DD")
    });
    refetch();
  };

  if (isLoading) {
    return "Carregando...";
  }

  const handleGenerateWithDollar = (dollar) => {
    refetch();
    setValue("dollar", dollar.dollar);
    setIsOpenModalReport(true);
  };

  const handleCancel = () => {
    setIsOpenModalReport(false);
  };

  const handlePrint = () => {
    window.print();
  };

  const handleFetchToReaport = async () => {
    setIsLoadingReaport(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/v1/withdrawal/to-reinvestment?startDate=${datetSearchWithdrawal.startDate}&endDate=${datetSearchWithdrawal.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json"
          }
        }
      );
      // if (!response.ok) {
      //   throw new Error(`Error! status: ${response.status}`);
      // }
      const result = await response.data;
      setIsLoadingReaport(false);
      Modal.info({
        width: "80%",
        title: "Confira os dados das transaçoes",
        content: (
          <>
            <Button onClick={handlePrint}>Imprimir</Button>
            <Table
              pagination={false}
              loading={isLoadingReaport}
              className="ant-border-space custom-row-hover"
              bordered
              dataSource={result}
              columns={[
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                  rowScope: "row",
                  fixed: "left"
                },
                {
                  title: "Saque Solicitado",
                  dataIndex: "requested",
                  key: "requested",
                  render: (amount, data) => (
                    <span>{formatCurrency(amount, data.currency)}</span>
                  )
                },
                {
                  title: "Saldo em operação",
                  dataIndex: "in_operation",
                  key: "in_operation",
                  render: (amount, data) => (
                    <span>{formatCurrency(amount, data.currency)}</span>
                  )
                },
                {
                  title: "Valor a ser reinvestido",
                  dataIndex: "to_reinvest",
                  key: "to_reinvest",
                  render: (amount, data) => (
                    <span>{formatCurrency(amount, data.currency)}</span>
                  )
                },
                {
                  title: "Status do pagamento",
                  dataIndex: "accepted",
                  key: "accepted",
                  render: (accepted) => {
                    return (
                      <>
                        {accepted === "0" ? (
                          <Tag color="red">Pendente</Tag>
                        ) : (
                          <Tag color="green">Pago</Tag>
                        )}
                      </>
                    );
                  },
                  width: "15%"
                }
              ]}
              rowKey="id"
            />
          </>
        )
      });
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finaly");
    }
  };

  return (
    <div className="layout-content">
      <Card
        bordered={true}
        className="criclebox mb-24 "
        title="Relatorio Saque e reinvestimeto"
      >
        <Flex justify="flex-start" gap={5} align="center">
          <Card title="Saques">
            <Form
              form={form}
              onFinish={handleGenerateWithDollar}
              layout="inline"
            >
              <Flex justify="flex-start" align="center">
                <Form.Item name="dollar" label="Dolar base calculo">
                  <Input type="number" {...register("dollar")} />
                </Form.Item>
                <Button className="" type="primary" htmlType="submit">
                  Gerar relatório
                </Button>
              </Flex>
            </Form>
          </Card>
          <Card title="Reinvestimentos">
            <Button
              loading={isLoadingReaport}
              className=""
              onClick={handleFetchToReaport}
            >
              {isLoadingReaport ? "LOADING..." : "Gerar relatório"}
            </Button>
          </Card>
        </Flex>
      </Card>

      <Divider />

      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Pedidos de Saques"
          >
            <Row>
              <Col span={24} style={{ height: 40, marginTop: 20 }}>
                <Form onFinish={searchBetweenDateWithdraw}>
                  <Flex justify="center" gap={5} align="center">
                    <Form.Item name="range_search" style={{ height: 20 }}>
                      <RangePicker
                        needConfirm
                        format={dateFormat}
                        style={{ height: 42 }}
                      />
                    </Form.Item>

                    <Button
                      loading={isLoading}
                      className=""
                      htmlType="submit"
                      type="primary"
                    >
                      Busca
                    </Button>
                  </Flex>
                </Form>
              </Col>
              <Col span={24}>
                <h2 style={{ textAlign: "center" }}>
                  {datetSearchWithdrawal.startDate} ate{" "}
                  {datetSearchWithdrawal.endDate}
                </h2>
              </Col>
            </Row>

            <div className="table-responsive">
              <Table
                layout=""
                columns={columns}
                dataSource={dataUser}
                pagination={true}
                className="ant-border-space custom-row-hover"
                bordered
                footer={() => (
                  <div>
                    <b>Total Saque Real:</b> {formatCurrency(totalBRL, "BRL")}{" "}
                    <br />
                    <b>Total Saque Dolar:</b>
                    {formatCurrency(totalUSD, "USD")}
                  </div>
                )}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        title=""
        open={isOpenModalReport}
        // onOk={handleOk}
        onCancel={handleCancel}
        width={"80%"}
      >
        <Button onClick={handlePrint}>Imprimir</Button>
        <Table
          pagination={false}
          className="ant-border-space custom-row-hover"
          bordered
          dataSource={data?.map((profile) => {
            return {
              key: profile?.id,
              name: profile?.profile?.name,
              withdrawalDollar: formatCurrency(
                profile?.amount,
                profile?.profile?.currency
              ),
              withdrawalReal:
                profile?.profile?.currency === "USD"
                  ? formatCurrency(
                      profile?.amount * getValues("dollar"),
                      profile?.profile?.currency
                    )
                  : formatCurrency(profile?.amount, profile?.profile?.currency),
              pix: profile?.profile?.pix,
              status: profile?.accepted
            };
          })}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name"
            },
            {
              title: "Saque Solicitado (dollar)",
              dataIndex: "withdrawalDollar",
              key: "withdrawalDollar"
            },
            {
              title: `Saque Solicitado (Real ${formatCurrency(
                getValues("dollar")
              )}) `,
              dataIndex: "withdrawalReal",
              key: "withdrawalReal"
            },
            {
              title: "Chave Pix",
              dataIndex: "pix",
              key: "pix"
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (status) => {
                return (
                  <>
                    {status ? (
                      <Tag color="green">Pago</Tag>
                    ) : (
                      <Tag color="red">Pendente</Tag>
                    )}
                  </>
                );
              }
            }
          ]}
        />
      </Modal>
    </div>
  );
}

export default Withdrawal;
