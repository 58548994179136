import axios from "axios";

const postTransaction = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/transaction/create`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const withdralTotal = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-total`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const withdralBeyond = async (values) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdrawal/withdrawal-beyond-profitability`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useTransactionPostRequest(data) {
  return postTransaction(data);
}

export function useTransactionTotalWithdraw(data) {
  return withdralTotal(data);
}

export function useWithdrawBeyondPostRequest(data) {
  return withdralBeyond(data);
}
