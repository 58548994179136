import { ProfileOutlined } from "@ant-design/icons";
import { Affix, Button, Card, Col, Flex, Menu, Row } from "antd";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const EntryAll = () => {
  const navigate = useNavigate();
  const handleClickMenu = (e) => {
    console.log("click", e);
    navigate(e.key);
  };

  return (
    <Row gutter={8}>
      <Col md={6} sm={24} className="no-print">
        <Affix offsetTop={55}>
          <Row>
            <Card
              style={{
                width: "100%"
              }}
            >
              <Menu
                onClick={handleClickMenu}
                style={{ marginTop: 15 }}
                items={[
                  getItem("Lançamento em Lote", ``, <ProfileOutlined />),
                  getItem(
                    "Lançamentos por cliente",
                    "clientes",
                    <ProfileOutlined />
                  )
                ]}
              />
            </Card>
          </Row>
        </Affix>
      </Col>
      <Col md={18} sm={24}>
        <Card
          style={{
            width: "100%"
          }}
        >
          <Outlet />
        </Card>
      </Col>
    </Row>
  );
};

export default EntryAll;
