import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAllContracts = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/v1/contracts`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`
      }
    });
    return data.data.data;
  } catch (error) {
    return error.response.status;
  }
};
const getContract = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/contracts/view?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data.data;
  } catch (error) {
    return error.response.status;
  }
};
const getContractZapSign = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/contracts/detail-contract?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return data.data.data;
  } catch (error) {
    return error.response.status;
  }
};

const updateContractInfo = async (data) => {
  const { id, values } = data;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/contracts/edit-contract?id=${id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const sendContractClient = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/contracts/send-contract?id=${data}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    console.log("LLL", response);
    return response.data;
  } catch (error) {
    console.log("RRR", error);
  }
};

export function useContractsRequest() {
  return useQuery(["contracts"], () => getAllContracts(), {
    onSuccess: (data) => {
      return data;
    }
  });
}

export function useContractRequest(id) {
  return useQuery(["contract"], () => getContract(id), {
    onSuccess: (data) => {
      return data;
    }
  });
}

export function useContractZapSignRequest(id) {
  return useQuery(["zapSignContract"], () => getContractZapSign(id), {
    enabled: !!id,
    onSuccess: (data) => {
      return data;
    }
  });
}

export function useContractUpdate(data) {
  return updateContractInfo(data);
}

export function useSendContractClient(data) {
  return sendContractClient(data);
}
