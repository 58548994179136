import { Button, Input, Space } from "antd";
import { useState } from "react";

export default function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Space.Compact
        style={{
          width: "100%"
        }}
      >
        <Input value={copyText} defaultValue={copyText} disabled readOnly />
        <Button type="primary" onClick={handleCopyClick}>
          {isCopied ? "Copiado!" : "Copiar"}
        </Button>
      </Space.Compact>
    </Space>
  );
}
