const palette = {
  primary: "rgb(151, 246, 117)",
  primaryDark: "#2B4622",

  secondary: "#F57A76",
  secondaryDark: "#462322",

  terc: "#F0F576",
  tercDark: "#462322"
};

export const colors = {
  palette
};
