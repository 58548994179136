import {
  DollarOutlined,
  DropboxOutlined,
  LockFilled,
  PaperClipOutlined,
  UndoOutlined,
  HeartTwoTone
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import api from "../../services/api";
import { Dashboard } from "../icons/Dashboard";
import { Profile } from "../icons/Profile";
import { useNavigate } from "react-router-dom";
import { useAccessFetchRequest } from "../../Hooks/access/useAccess.query";
import { colors } from "../../Themes";

function Sidenav(props) {
  const navigate = useNavigate();
  const { color } = props;
  let { pathname } = useLocation();
  const page = pathname.split("/");

  pathname = pathname.replace("/", "");

  const { data, isLoading: loading, status } = useAccessFetchRequest();
  const access = data?.data.access;

  const MenuItems = [
    {
      title: "Dashboard",
      link: "painel",
      icon: <Dashboard color={colors.palette.primaryDark} />,
      display: access >= 50
    },
    {
      title: "Contratos",
      link: "contratos",
      icon: <Profile color={colors.palette.primaryDark} />,
      display: access >= 50
    },
    {
      title: "Pessoas",
      link: "investidor",
      icon: <Profile color={colors.palette.primaryDark} />,
      display: access >= 50
    },
    {
      title: "Escritórios",
      link: "escritorios",
      icon: <HeartTwoTone />,
      display: access >= 50
    },
    {
      title: "Lançamentos",
      link: "lancamentos",
      icon: <UndoOutlined color={color} />,
      display: access >= 99
    },
    // {
    //   title: "Lançamento em Lote",
    //   link: "lote",
    //   icon: <DropboxOutlined color={color} />,
    //   display: access >= 99
    // },
    {
      title: "Planos",
      link: "planos",
      icon: <DollarOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Notificação",
      link: "notificacao",
      icon: <DollarOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Campanha",
      link: "promocoes",
      icon: <DollarOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Copy Trading",
      link: "copy",
      icon: "₢",
      display: access >= 99
    },
    {
      title: "Solicitação de Saque",
      link: "saque",
      icon: <DollarOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Pagamento de G.N.",
      link: "pagamento-gerente",
      icon: <DollarOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Relatórios",
      link: "relatorios",
      icon: <PaperClipOutlined color={color} />,
      display: access >= 99
    },
    {
      title: "Função",
      link: "role",
      icon: <LockFilled color={color} />,
      display: access >= 99
    },
    {
      title: "Log",
      link: "log",
      icon: <LockFilled color={color} />,
      display: access >= 99
    }
  ];

  let activePage = null;
  if (page.length === 1) {
    activePage = page[0];
  }
  if (page.length > 1) {
    activePage = page[1];
  }

  return (
    <div className="no-print">
      <div className="brand" style={{ textAlign: "center" }}>
        <img src={logo} alt="" style={{ height: "60px" }} />
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {MenuItems.map((menu, index) => {
          return (
            menu.display && (
              <Menu.Item key={index}>
                <NavLink to={`${menu.link}`}>
                  <span
                    className="icon"
                    style={{
                      background: activePage === `${menu.link}` ? color : ""
                    }}
                  >
                    {menu.icon}
                  </span>
                  <span className="label">{menu.title}</span>
                </NavLink>
              </Menu.Item>
            )
          );
        })}
      </Menu>
    </div>
  );
}

export default Sidenav;
