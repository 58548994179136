export const validateEmail = (email) => {
  var expression = /\S+@\S+\.\S+/;
  return expression.test(email);
};

export const validateCpf = (cpf) => {
  var expression =
    /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|([0-9]{11})|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;
  return expression.test(cpf);
};

export const validateRg = (rg) => {
  var expression = /(^\d{2}\.\d{3}\.\d{3}\-\d{1}$)|([0-9]{9})/;
  return expression.test(rg);
};

export const validateCnh = (cnh) => {
  var expression = /([0-9]{11})/;
  return expression.test(cnh);
};

export const validatePhone = (phone) => {
  var expression = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}\-?[0-9]{4}$/;
  return expression.test(phone);
};

export const validateCep = (cep) => {
  var expression = /(^\d{2}\.\d{3}\-\d{3}$)|([0-9]{8})/;
  return expression.test(cep);
};
