import { Divider, Form, Input, notification } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const CreateNotification = () => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  return (
    <>
      <Title level={4}>Criar Notificação</Title>
      <Divider />
      <Form
        {...layout}
        layout="horizontal"
        form={form}
        name="create-user"
        // onFinish={onFinish}
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
      >
        <Form.Item name="title" label="Titulo" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="message" label="Mensagem" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateNotification;
