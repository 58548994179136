import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Descriptions, Divider } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useParams } from "react-router-dom";
import DocumentAssigned from "../../components/uploads/DocumentAssigned";

const Documentation = () => {
  const { id } = useParams();
  const [hiddenDocument, setHiddenDocument] = useState(false);

  const { data: dataInvestor, isLoading } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const handleHiddenDocument = () => {
    setHiddenDocument(!hiddenDocument);
  };

  return (
    <>
      <Title level={4}>Documentos</Title>
      <Divider />
      <Descriptions>
        <Descriptions.Item label="Enviar documento" span={3}>
          <DocumentAssigned id={investor?.id} title={investor?.document} />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default Documentation;
