import { Tag } from "antd";

const status = {
  0: "Invalid",
  1: "Canceled",
  2: "Declined",
  3: "Waiting",
  4: "Authorized",
  5: "Unauthorized"
};

const color = {
  0: "#FFB6C1 ",
  1: "#87CEEB ",
  2: "#FFDAB9 ",
  3: "#FFFF99 ",
  4: "#98FB98 ",
  5: "#FFA07A "
};

export const withdrawStatus = (type) => {
  return <Tag color={color[type]}>{status[type] || ""}</Tag>;
};
