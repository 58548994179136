import { Skeleton, Space } from "antd";
import React from "react";

const SkeletonLoading = () => {
  return (
    <>
      <Skeleton
        avatar
        paragraph={{
          rows: 4
        }}
      />
    </>
  );
};

export default SkeletonLoading;
