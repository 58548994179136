import { useMutation } from "@tanstack/react-query";
import { IMaskInput } from "react-imask";
import {
  App,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Typography,
  notification
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BgImage from "../assets/images/loginBackground.png";
import logo from "../assets/images/logo.png";
import { colors } from "../Themes";

const { Sider, Content } = Layout;
const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  background: "#ffffff22",
  backdropFilter: "blur(30px)",
  borderRadius: "0 5px 5px 0"
};
const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#dddddd11"
};
const layoutStyle = {
  height: "100vh",
  background: ` no-repeat center/100% url(${BgImage})`,
  backgroundSize: "cover"
};

const SignIn = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const loginUser = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/site/login`,
      user,
      {
        headers: {
          "Content-Type": "application/json"
        },
        validateStatus: function (status) {
          return true;
        }
      }
    );

    return response.data;
  };
  const { isLoading, mutate } = useMutation(loginUser, {
    onSuccess: (data) => {
      console.log(data);
      if (data.status === 400) {
        notification.error({
          message: `ERROR ${data.status}`,
          description: data.message
        });
        return;
      }
      if (data.status === 200) {
        localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          data.data.token.token
        );
        notification.success({
          message: "Sucesso.",
          description: `${data.message} redirecionando ao painel. `
        });
        setTimeout(1000);
        navigate("/painel");
      }
    },
    onError: (error) => {
      notification.error({
        message: "Erro",
        description: error
      });
    }
  });

  const onFinish = (values) => {
    mutate(values);
  };

  return (
    <Layout>
      <Layout style={layoutStyle} hasSider>
        <Sider style={siderStyle} width={"35%"}>
          <Content className="signin">
            <img src={logo} alt="" style={{ height: "135px" }} />
            <Row gutter={[24, 0]} justify="space-around">
              <Col
                xs={{ span: 24, offset: 0 }}
                lg={{ span: 20, offset: 0 }}
                md={{ span: 24 }}
              >
                <Form onFinish={onFinish} layout="vertical" className="row-col">
                  <Form.Item
                    className="login-form"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your credential!"
                      }
                    ]}
                  >
                    <IMaskInput
                      mask="000.000.000-00"
                      placeholder="Digite o seu CPF"
                      style={{
                        height: "50px",
                        borderRadius: "4px",
                        width: "100%",
                        border: "none",
                        color: "#777777",
                        paddingLeft: "10px",
                        fontWeight: 600
                      }}
                    />
                  </Form.Item>
                  {/* <Form.Item
                    className="username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your credential!"
                      }
                    ]}
                  >
                    <Input
                      placeholder="CPF"
                      style={{ height: "50px", borderRadius: "4px" }}
                    />
                  </Form.Item> */}

                  <Form.Item
                    className="login-form"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!"
                      }
                    ]}
                  >
                    <Input.Password
                      placeholder="SENHA"
                      style={{
                        height: "50px",
                        borderRadius: "4px",
                        width: "100%",
                        border: "none",
                        color: "#777777",
                        paddingLeft: "10px",
                        fontWeight: 600
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      loading={!!isLoading}
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "100%",
                        height: 45,
                        backgroundColor: colors.palette.primary,
                        color: colors.palette.primaryDark
                      }}
                    >
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
        </Sider>
        <Content style={contentStyle}>
          {/* <Typography.Title
            style={{
              fontSize: "70px",
              top: "50px",
              color: "#fff",
              textShadow: "5px 5px 5px #000",
              marginTop: "120px"
            }}
          >
            Be part of it.
          </Typography.Title> */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SignIn;
