import {
  Avatar,
  Card,
  Col,
  Divider,
  Flex,
  List,
  Row,
  Space,
  Spin,
  Statistic
} from "antd";
import React from "react";
import {
  useOperatingBalanceRequest,
  useWalletAmountBalanceFetchRequest,
  useWalletExpenseBalanceFetchRequest
} from "../../Hooks/wallet/useWallet.query";
import { useInvestorsFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import Title from "antd/es/typography/Title";
import { usePromotionsRequest } from "../../Hooks/promotions/usePromotions.query";
import {
  useManagersRequest,
  useRankingManagersRequest
} from "../../Hooks/manager/useManager.query";
import DailyMotivationalQuote from "../../components/Messages/dailyMotivationalQuote";
import Statistics from "./statistics";
import { useUsersCountRequest } from "../../Hooks/dashboard/useDashboard.query";
import { Link } from "react-router-dom";

const Home = () => {
  const { data: dataInvestors, isLoading } =
    useInvestorsFetchRequest("profile, wallet");
  const investor = dataInvestors?.data?.data;
  //
  const { data: countUsers, isLoading: loadingCountUsers } =
    useUsersCountRequest();
  console.log("countUsers", countUsers);

  const { data: operatingBalance, isLoading: loadingoperatingBalance } =
    useOperatingBalanceRequest();
  console.log("operatingBalance", operatingBalance);
  //
  const { data: dataExpense, isLoading: loadingExpense } =
    useWalletExpenseBalanceFetchRequest("expense");
  const { data: dataManagers } = useManagersRequest();
  const { data: dataPromotions } = usePromotionsRequest();
  const { data: dataRanking, isLoading: loadingRanking } =
    useRankingManagersRequest();
  console.log("dataManagers:", dataManagers);
  const managers = dataManagers?.data?.data;
  const promotions = dataPromotions?.data.data;

  function userIsBlock(usuarios) {
    let contagem = 0;

    for (const usuario of usuarios) {
      if (usuario?.user?.status !== 10) {
        contagem++;
      }
    }

    return contagem;
  }
  function userNotBlock(usuarios) {
    let contagem = 0;

    for (const usuario of usuarios) {
      if (usuario?.user?.status === 10) {
        contagem++;
      }
    }

    return contagem;
  }

  function isManager(usuarios) {
    let contagem = 0;

    for (const usuario of usuarios) {
      if (usuario?.user?.access_given === 50) {
        contagem++;
      }
    }

    return contagem;
  }

  if (isLoading) {
    return (
      <div
        className="layout-content"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className="layout-content">
      <Row gutter={5}>
        <Col xs={24} className="mb-24">
          <div
            style={{
              backgroundSize: "cover",
              backgroundColor: "#fefefe",
              border: "1px solid #e8e8e8",
              height: 200,
              width: "100%",
              borderRadius: "10px"
            }}
          >
            <div
              style={{
                position: "absolute",
                padding: "0px 25px",
                width: "100%"
              }}
            >
              <Row gutter={5} justify={"center"}>
                <Col xs={2} sm={4} md={12} lg={12} xl={12}>
                  <Title style={{ color: "#2b4622" }} level={4}>
                    Saldo em operação
                  </Title>
                  <Row gutter={5}>
                    <Statistic
                      loading={loadingoperatingBalance}
                      style={{
                        width: "50%"
                      }}
                      title="Dolar"
                      value={formatCurrency(
                        operatingBalance.operating?.USD,
                        "USD"
                      )}
                      valueStyle={{
                        color: "#3f8600"
                      }}
                    />
                    <Statistic
                      loading={loadingoperatingBalance}
                      style={{ width: "50%" }}
                      title="Real"
                      value={formatCurrency(
                        operatingBalance.operating?.BRL,
                        "BRL"
                      )}
                      valueStyle={{
                        color: "#3f8600"
                      }}
                    />
                  </Row>
                </Col>

                <Col xs={2} sm={4} md={12} lg={12} xl={12}>
                  <Title style={{ color: "#2b4622" }} level={4}>
                    Saldo aportado
                  </Title>
                  <Row gutter={5}>
                    <Statistic
                      loading={loadingoperatingBalance}
                      style={{ width: "50%" }}
                      title="Dolar"
                      value={formatCurrency(
                        operatingBalance.contributed?.USD,
                        "USD"
                      )}
                      valueStyle={{
                        color: "#3f8600"
                      }}
                    />
                    <Statistic
                      loading={loadingoperatingBalance}
                      style={{ width: "50%" }}
                      title="Real"
                      value={formatCurrency(
                        operatingBalance.contributed?.BRL,
                        "BRL"
                      )}
                      valueStyle={{
                        color: "#3f8600"
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={5}>
        <Col span={24} className="mb-24">
          <Row gutter={5}>
            <Statistics
              isLoading={loadingCountUsers}
              title="Total de usuários Ativos"
              amount={countUsers.active}
              color={""}
            />
            <Statistics
              isLoading={loadingCountUsers}
              title="Investidores Ativos"
              amount={countUsers.isInvestor}
              color={""}
            />
            <Statistics
              isLoading={loadingCountUsers}
              title="Gestores de Negócio Ativos"
              amount={countUsers.isManager}
              color={""}
            />
            <Statistics
              isLoading={loadingCountUsers}
              title="Pendentes de Ativação"
              amount={countUsers.pending}
              color={""}
            />
            <Statistics
              isLoading={loadingCountUsers}
              title="Escritorios"
              amount={countUsers.isOffice}
              color={""}
            />
            <Statistics
              isLoading={loadingCountUsers}
              title="Desativados"
              amount={countUsers.inactive}
              color={""}
            />
          </Row>
        </Col>
      </Row>

      <Row gutter={5}>
        <Col
          xs={{ span: 24, order: 2 }}
          sm={{ span: 16, order: 2 }}
          md={{ span: 14, order: 2 }}
          lg={{ span: 16, order: 1 }}
          xl={{ span: 15, order: 1 }}
        >
          <Row gutter={5}>
            <Col span={24} className="mb-24">
              <Card title="Promoções ativas">
                <List
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={promotions}
                  renderItem={(item, index) => (
                    <List.Item
                      extra={
                        <p>
                          {item.start_date} <small>até</small> {item.end_date}
                        </p>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={`https://api.dicebear.com/8.x/identicon/svg?seed=${index}`}
                          />
                        }
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>

            <Col span={24} className="mb-24">
              <Card title="Gerentes de Negocios ativos">
                <List
                  pagination={{
                    onChange: (page) => {},
                    pageSize: 5
                  }}
                  itemLayout="horizontal"
                  dataSource={dataManagers}
                  renderItem={(item, index) => (
                    <List.Item
                      extra={
                        <>
                          <p>
                            {item.sum_income?.BRL !== 0 &&
                              `Valor Real: ${formatCurrency(
                                item.sum_income?.BRL,
                                "BRL"
                              )}`}
                            <br />
                            {item.sum_income?.USD !== 0 &&
                              `Valor Dolar: ${formatCurrency(
                                item.sum_income?.USD,
                                "USD"
                              )}`}
                          </p>
                        </>
                      }
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.manager_avatar ||
                              `https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item?.manager_name}`
                            }
                          />
                        }
                        title={
                          <Link
                            to={`/investidor/perfil/${item?.manager_id}/informacoes-pessoais`}
                          >
                            {item?.manager_name}
                          </Link>
                        }
                        description={item?.profile?.office}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        {/* // */}
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 8, order: 1 }}
          md={{ span: 10, order: 1 }}
          lg={{ span: 8, order: 2 }}
          xl={{ span: 9, order: 2 }}
        >
          <Row gutter={10}>
            <Col xs={24} className="mb-24">
              <div
                style={{
                  backgroundSize: "cover",
                  backgroundImage:
                    "url(https://demos.creative-tim.com/muse-ant-design-dashboard-pro/static/media/info-card-2.9a5119d1.jpg)",
                  height: 200,
                  width: "100%",
                  borderRadius: "10px"
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    padding: "10px"
                  }}
                >
                  <Title style={{ color: "#efefef" }} level={4}>
                    Bem Vindo ✌️
                  </Title>
                  <DailyMotivationalQuote />
                </div>
              </div>
            </Col>

            <Col span={24} className="mb-24">
              <Card title="Ranking de GERENTES">
                <List
                  loading={loadingRanking}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#efefef",
                        borderRadius: "10px",
                        position: "relative",
                        overflow: "hidden"
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "10px",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundImage:
                            "url(https://t4.ftcdn.net/jpg/00/77/99/55/360_F_77995519_0j69tNQChmaopuUdZNufM4BzxzYRMm2C.jpg)",
                          backgroundSize: "cover",
                          zIndex: "0",
                          filter: "blur(2px)",
                          opacity: "0.5"
                          // backgroundAttachment: "fixed"
                        }}
                      ></div>
                      <Title
                        level={4}
                        style={{
                          marginTop: "15px",
                          marginBottom: "15px",
                          zIndex: "1"
                        }}
                      >
                        Invenstimentos em DOLAR
                      </Title>
                    </div>
                  }
                  itemLayout="horizontal"
                  dataSource={dataRanking?.USD}
                  renderItem={(item, index) => (
                    <>
                      <List.Item
                        extra={<p>{formatCurrency(item.amount, "USD")}</p>}
                      >
                        <Title
                          level={5}
                          style={{
                            width: 30,
                            height: 45,
                            textAlign: "center",
                            color: "#222222"
                          }}
                        >
                          {item.rank}
                        </Title>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={
                                item.avatar ||
                                `https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item.user_name}`
                              }
                            />
                          }
                          title={
                            <Link
                              to={`/investidor/perfil/${item?.UID}/informacoes-pessoais`}
                            >
                              {item.user_name}
                            </Link>
                          }
                          // description={item.profile.office}
                        />
                      </List.Item>
                    </>
                  )}
                />
                <Divider />
                <List
                  loading={loadingRanking}
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#efefef",
                        borderRadius: "10px",
                        position: "relative",
                        overflow: "hidden"
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "10px",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundImage:
                            "url(https://i.pinimg.com/736x/e4/03/c4/e403c4447a3bd8940459ae4f50856bed.jpg)",
                          backgroundSize: "cover",
                          zIndex: "0",
                          filter: "blur(2px)",
                          opacity: "0.5"
                          // backgroundAttachment: "fixed"
                        }}
                      ></div>
                      <Title
                        level={4}
                        style={{
                          marginTop: "15px",
                          marginBottom: "15px",
                          zIndex: "1"
                        }}
                      >
                        Invenstimentos em REAL
                      </Title>
                    </div>
                  }
                  itemLayout="horizontal"
                  dataSource={dataRanking?.BRL}
                  renderItem={(item, index) => (
                    <>
                      <List.Item
                        extra={<p>{formatCurrency(item.amount, "BRL")}</p>}
                      >
                        <Title
                          level={5}
                          style={{
                            width: 30,
                            height: 45,
                            textAlign: "center",
                            color: "#222222"
                          }}
                        >
                          {item.rank}
                        </Title>
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={
                                item.avatar ||
                                `https://api.dicebear.com/8.x/fun-emoji/svg?seed=${item.user_name}`
                              }
                            />
                          }
                          title={item.user_name}
                          // description={item.profile.office}
                        />
                      </List.Item>
                    </>
                  )}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
