import { Button, Card, Divider, Flex, Table } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { usePromotionsRequest } from "../../Hooks/promotions/usePromotions.query";
import { NavLink } from "react-router-dom";

const columns = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Descrição",
    dataIndex: "description",
    key: "description"
  },
  {
    title: "Data Inicio",
    dataIndex: "start_date",
    key: "start_date"
  },
  {
    title: "Data Fim",
    dataIndex: "end_date",
    key: "end_date"
  }
];

const Promotions = () => {
  const { data } = usePromotionsRequest();
  const promotionsData = data?.data.data;

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Button>
          <NavLink to="criar">Nova Promoção</NavLink>
        </Button>
      </Flex>
      <Divider />
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title={<Title level={4}>Promoções</Title>}
      >
        <Table columns={columns} dataSource={promotionsData} />
      </Card>
    </div>
  );
};

export default Promotions;
