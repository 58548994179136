import { EditTwoTone } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tag,
  notification
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useInvestorFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { typeFormat } from "../../helpers/formatType";
import { formatCurrency } from "../../helpers/moneyFormat";
import { withdrawStatus } from "../../helpers/withdrawStatus";

const Transactions = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [idTransaction, setIdTransaction] = useState(null);
  const queryClient = useQueryClient();

  const { data: dataInvestor } = useInvestorFetchRequest(id);
  const investor = dataInvestor?.data?.data;

  const handleClickUpdateRentability = (e) => {
    setIdTransaction(e);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text) => (
        <>
          <Button
            type="link"
            onClick={() => handleClickUpdateRentability(text)}
          >
            <EditTwoTone />
          </Button>
        </>
      ),
      width: "5%"
    },
    {
      title: "Tipo",
      dataIndex: "type_transaction",
      key: "type_transaction",
      render: (text) => (
        <>
          <Tag color={"#444"}>{typeFormat(text)}</Tag>
        </>
      ),
      width: "10%"
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (text) => <p>{text}</p>,
      width: "15%"
    },
    {
      title: "Porcentagem",
      dataIndex: "percent",
      key: "percent",
      render: (text) => <p>{text} %</p>,
      width: "5%"
    },
    {
      title: "Percentual",
      dataIndex: "base_calculation_percentage",
      key: "base_calculation_percentage",
      render: (text) => <p>{text}</p>,
      width: "5%"
    },
    {
      title: "Valor",
      dataIndex: "amount_money",
      key: "amount_money",
      render: (text) => <>{formatCurrency(text, investor?.currency)}</>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <p>{withdrawStatus(text)}</p>
    }
  ];
  const dataTransactions = investor?.transactions.map((transaction) => {
    return {
      actions: transaction.id,
      key: transaction.id,
      date: transaction.date,
      type_transaction: transaction.type_transaction,
      percent: transaction.percent,
      base_calculation_percentage: transaction.base_calculation_percentage,
      amount_money: transaction.amount_money,
      description: transaction.description,
      status: transaction.status
    };
  });

  /** */
  const put = async (dataForm) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/transaction/update-profit?id=${dataForm.id}`,
      dataForm,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      }
    );
    return response.data;
  };
  const { mutateAsync } = useMutation(put, {
    onSuccess: (data) => {
      setIsModalVisible(false);
      form.resetFields();
      queryClient.invalidateQueries("adminInvestor");
      api.success({
        message: "Sucesso",
        description: "Lançamento feito com sucesso."
      });
    },
    onError: (error) => {
      api.error({
        message: `Error: [${error.response.data.status}] ${error.response.data.name}`,
        description: error.response.data.message
      });
    }
  });
  const onFinish = (values) => {
    mutateAsync(values, idTransaction);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  /** */
  return (
    <>
      {contextHolder}
      <Col xs="24" xl={24}>
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={dataTransactions}
            pagination={false}
            className="ant-border-space custom-row-hover"
            bordered
            expandable={{
              expandedRowRender: (record) => (
                <p
                  style={{
                    margin: 0,
                    width: "90%",
                    whiteSpace: "pre-line"
                  }}
                >
                  {record.description.replace(/\|/g, " \r\n")}
                </p>
              ),
              rowExpandable: (record) => record.name !== "Not Expandable"
            }}
          />
        </div>
      </Col>

      <Modal
        title="Alterar Rentabilidade"
        open={isModalVisible}
        footer={false}
        // onOk={onFinish}
        onCancel={handleCancel}
      >
        <Form
          preserve={false}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          fields={[
            {
              name: ["id"],
              value: idTransaction
            }
          ]}
        >
          <Col>
            <Row wrap={false}>
              <Col flex="auto">
                <Form.Item name="percent">
                  <Input prefix="%" type="number" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row wrap={false}>
              <Col flex="auto">
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col flex="auto">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Salvar
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default Transactions;
