const types = {
  1: "Cliente",
  50: "G.N",
  99: "ADMIN",
  80: "Escritorio",
  60: "Gerente Senior ",
  5: "Colaborador"
};

export const typeAccess = (type) => {
  return types[type] || "";
};
