import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Modal, Table, Tag, notification } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useCopyUpdateRequest,
  useCopysFetchRequest
} from "../../Hooks/copy/useCopy.query";
import { TableCopy } from "../../components/Table";
import { convertTimestampToDate } from "./../../helpers/convertTimestampToDate";
import { formatCurrency } from "./../../helpers/moneyFormat";

const Copy = () => {
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();
  const { data, isLoading } = useCopysFetchRequest();

  const dataAdvice = data?.map((copy, index) => {
    return {
      id: index,
      key: copy.id,
      name: copy.name,
      voucher: copy.voucher,
      amount: copy.price_sale,
      date: copy.created_at,
      new: copy,
      status: copy.status
    };
  });

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (_, text) => <>{text.name}</>,
      width: "80%"
      // ...getColumnSearchProps("name"),
    },
    {
      title: "Valor Entrada",
      dataIndex: "amount",
      key: "amount",
      render: (text) => formatCurrency(text)
    },
    {
      title: "Data de entrada",
      dataIndex: "date",
      key: "date",
      render: (text) => convertTimestampToDate(text)
    },
    {
      title: "Comprovante",
      dataIndex: "voucher",
      key: "voucher",
      render: (_, { voucher }) => (
        <>
          <Button
            type="link"
            target="_blank"
            rel="noopener noreferrer"
            href={voucher}
          >
            Comprovante
          </Button>
        </>
      ),
      width: "10%"
    },
    {
      title: "Novos Aportes",
      dataIndex: "new",
      key: "new",
      render: (_, doc) => (
        <>
          <Button
            type="primary"
            size="large"
            onClick={() => handleOpenNewAport(doc?.new)}
          >
            Novo
          </Button>
        </>
      ),
      width: "10%"
    },
    {
      title: "Aprovar",
      dataIndex: "aprove",
      key: "aprove",
      render: (_, doc) => (
        <>
          {doc?.status === 10 ? (
            ""
          ) : (
            <Button
              type="primary"
              size="large"
              onClick={() => handlerAprove(doc)}
            >
              Aprovar
            </Button>
          )}
        </>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <>
          {status === 0 && <Tag color="gray">AGUARDANDO</Tag>}
          {status === 10 && <Tag color="green">APROVADO</Tag>}
          {status === 1 && <Tag color="volcano">BLOQUEADO</Tag>}
        </>
      ),
      // render: (_, doc) => <Tag color="gray">AGURADANDO</Tag>,
      width: "10%"
    }
  ];

  const { mutateAsync } = useMutation(useCopyUpdateRequest, {
    onSettled: () => {
      queryClient.invalidateQueries("manager-copys");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("manager-copys");
    }
  });

  const handlerAprove = (data) => {
    const dataSender = {
      id: data.key,
      status: 10
    };
    mutateAsync(dataSender);
  };

  const handleOpenNewAport = (content) => {
    setOpenModal(true);
    setContentModal(content);
  };
  return (
    <div className="layout-content">
      {contextHolder}
      <Col>
        <Button type="primary" className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button>
      </Col>

      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <div className="table-responsive">
          <Table
            loading={isLoading ? true : false}
            columns={columns}
            dataSource={dataAdvice}
            pagination={false}
            className="ant-border-space custom-row-hover"
            bordered
          />
        </div>

        <Modal
          title={contentModal?.name}
          centered
          open={openModal}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          width={1000}
          destroyOnClose={true}
          footer={null}
        >
          <TableCopy data={contentModal} />
        </Modal>
      </Card>
    </div>
  );
};

export default Copy;
