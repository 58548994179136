import React, { useState } from "react";
import {
  Flex,
  Divider,
  Card,
  Table,
  Modal,
  Button,
  Form,
  Input,
  notification,
  Row,
  Col,
  Menu
} from "antd";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useContractRequest,
  useContractUpdate,
  useSendContractClient
} from "../../Hooks/contracts/useContracts.query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ProfileOutlined } from "@ant-design/icons";
import { validateEmail } from "../../helpers/validateInputsPersonal";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}

const ViewContract = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openConfirmSendContract, setOpenConfirmSendContract] = useState(false);
  const [openConfirmSendContractError, setOpenConfirmSendContractError] =
    useState(false);
  const { data: contract, isLoading: loadingContract } = useContractRequest(id);

  const handleOpenEdit = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { isLoading, mutateAsync } = useMutation(useContractUpdate, {
    onSuccess: (data) => {
      if (data.status === 400) {
        api.error({
          message: "Error",
          description: data.message
        });
        return;
      }
      form.resetFields();
      queryClient.invalidateQueries("contract");
      api.success({
        message: "Sucesso",
        description: "Dados Salvo com sucesso"
      });
      setIsModalOpen(false);
      // navigate(`/investidor/perfil/${id}`);
    },
    onError: (err) => {
      api.error({
        message: "Error",
        description: err.error
      });
    }
  });

  const { mutateAsync: sendContract, isLoading: sendingContract } = useMutation(
    useSendContractClient,
    {
      onSuccess: (data) => {
        if (data.status === 500) {
          api.error({
            message: "Error",
            description: data.message
          });
          return;
        }
        api.success({
          message: "Sucesso",
          description: "Dados Salvo com sucesso"
        });
      },
      onError: (err) => {
        console.error(":", err);
        api.error({
          message: "Errorss",
          description: err.error
        });
      }
    }
  );

  const onFinish = (values) => {
    mutateAsync({ id, values });
    // setIsModalOpen(false);
  };

  const handleSendContract = () => {
    setOpenConfirmSendContract(true);
  };

  const handleConfirmSendContract = () => {
    console.log("send contract", id);
    sendContract(id);
  };

  const handleClickMenu = (e) => {
    console.log("click", e);
    navigate(e.key);
  };
  if (loadingContract) {
    return "carregando....";
  }
  return (
    <>
      {contextHolder}

      <Modal
        title="Enviar contrato para assinatura"
        open={openConfirmSendContract}
        onCancel={() => setOpenConfirmSendContract(false)}
        footer={false}
        destroyOnClose={true}
        maskClosable={true}
      >
        {validateEmail(contract?.email) ? (
          <>
            <p>
              O contrato para <strong>{contract?.name}</strong> está pronto.
              Clique no botão abaixo para enviar o contrato para o e-mail:{" "}
              <strong>{contract?.email}</strong>
            </p>
            <Button
              type="primary"
              onClick={handleConfirmSendContract}
              // loading={sendingContract}
              isLoading={sendingContract}
            >
              Enviar Contrato
            </Button>
          </>
        ) : (
          <p>
            Não foi possível enviar o contrato para{" "}
            <strong>{contract?.name}</strong> porque o e-mail fornecido não é
            válido. Por favor, atualize o endereço de e-mail antes de prosseguir
            com o envio do contrato.
          </p>
        )}
      </Modal>

      <Row gutter={8}>
        <Col md={6} sm={24} className="no-print">
          <Row>
            <Card
              style={{
                width: "100%"
              }}
            >
              <Menu
                onClick={handleClickMenu}
                style={{ marginTop: 15 }}
                items={[
                  getItem("Detalhes", `detalhes`, <ProfileOutlined />),
                  getItem(
                    "Visualizar contrato",
                    "contrato",
                    <ProfileOutlined />
                  )
                ]}
              />
              <Menu
                onClick={handleOpenEdit}
                items={[
                  {
                    label: "Editar",
                    key: "mail",
                    icon: <ProfileOutlined />
                  }
                ]}
              />
              <Menu
                onClick={handleSendContract}
                items={[
                  {
                    label: "Enviar",
                    key: "mail",
                    icon: <ProfileOutlined />
                  }
                ]}
              />
            </Card>
          </Row>
        </Col>
        <Col md={16} sm={24}>
          <Flex
            justify="center"
            align="center"
            style={{ margin: "10px" }}
            gap={8}
            className="no-print"
          >
            {/* <Button onClick={handleOpenEdit}>Editar</Button> */}
            {/* <Button onClick={handleSendContract}>Enviar</Button> */}
          </Flex>
          <Row>
            <Outlet />
          </Row>
        </Col>
      </Row>

      <Modal
        title="Editar Dados Contrato"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        destroyOnClose={true}
        maskClosable={true}
      >
        <Form
          style={{ paddingLeft: "20px", paddingRight: "20px" }}
          layout="vertical"
          form={form}
          name="create-user"
          onFinish={onFinish}
          fields={[
            {
              name: ["name"],
              value: contract?.name
            },
            {
              name: ["email"],
              value: contract?.email
            },
            {
              name: ["marital_status"],
              value: contract?.estado_civil
            },
            {
              name: ["profession"],
              value: contract?.profissao
            },
            {
              name: ["number_rg"],
              value: contract?.rg
            },
            {
              name: ["number_cpf"],
              value: contract?.cpf
            },
            {
              name: ["number_cnh"],
              value: contract?.cnh
            },
            {
              name: ["phone"],
              value: contract?.phone
            },
            {
              name: ["whatsapp"],
              value: contract?.whatsapp
            },
            {
              name: ["postal_code"],
              value: contract?.cep
            },
            {
              name: ["street"],
              value: contract?.endereco
            },
            {
              name: ["number"],
              value: contract?.number
            },
            {
              name: ["neighborhood"],
              value: contract?.bairro
            },
            {
              name: ["city"],
              value: contract?.cidade
            },
            {
              name: ["state"],
              value: contract?.estado
            },
            {
              name: ["observation"],
              value: contract?.valor
            }
          ]}
        >
          <Form.Item name="name" label="Nome">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="marital_status" label="Estado Civil">
            <Input />
          </Form.Item>
          <Form.Item name="profession" label="Profissão">
            <Input />
          </Form.Item>
          <Form.Item name="number_rg" label="RG">
            <Input />
          </Form.Item>
          <Form.Item name="number_cpf" label="CPF">
            <Input />
          </Form.Item>
          <Form.Item name="number_cnh" label="CNH">
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Telefone">
            <Input />
          </Form.Item>
          <Form.Item name="whatsapp" label="Whatsapp">
            <Input />
          </Form.Item>
          <Form.Item name="postal_code" label="CEP">
            <Input />
          </Form.Item>
          <Form.Item name="street" label="Rua">
            <Input />
          </Form.Item>
          <Form.Item name="number" label="Numero">
            <Input />
          </Form.Item>
          <Form.Item name="neighborhood" label="bairro">
            <Input />
          </Form.Item>
          <Form.Item name="city" label="Cidade">
            <Input />
          </Form.Item>
          <Form.Item name="state" label="Estado">
            <Input />
          </Form.Item>
          <Form.Item name="observation" label="Observação">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              loading={!!isLoading}
              style={{ width: "100%", marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ViewContract;
