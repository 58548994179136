import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React from "react";
import Report00001Pdf from "../../Templates/Reports/00001Pdf";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const List = () => {
  const [form] = Form.useForm();
  const tblRef = React.useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const [openTransactionsType, setOpenTransactionsType] = React.useState(false);
  const [responseReports, setResponseReports] = React.useState([]);
  const [empty, setEmpty] = React.useState(false);
  const [type, setType] = React.useState();

  const { data: dataSelectPeople, status: statusPeople } = useQuery(
    ["peoples", 1],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API}/v1/profile/people-by-role?expand=profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_ACCESS_TOKEN
              )}`
            }
          }
        )
        .then((res) => {
          return res.data.data;
        })
  );

  // const handleChange = (value) => {
  //   setSelectPeople(value);
  // };

  const handleOpenTypeTransactions = () => {
    setOpenTransactionsType(!openTransactionsType);
  };

  const getReport = (dataForm) => {
    console.log("dataForm", dataForm);
    const response = axios
      .get(
        `${process.env.REACT_APP_API}/v1/reports?params=${JSON.stringify(
          dataForm
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        }
      )
      .then((res) => {
        // console.log(">>>", res.data);
        setResponseReports(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // console.log("responseReports", responseReports);

  const { mutate, isLoading } = useMutation(getReport, {
    onSuccess: (data) => {
      api.info({
        message: `Notificação`,
        description: "relatorio gerado com sucesso"
      });
    },
    onError: (error) => {
      console.log("error: ", error);
    }
  });

  const onFinish = (valuesInput) => {
    const rangeValue = valuesInput["date_range"];
    const values = {
      ...valuesInput,
      between_transactions: rangeValue
      // between_transactions: [
      //   rangeValue[0].format("YYYY-MM-DD"),
      //   rangeValue[1].format("YYYY-MM-DD")
      // ]
    };
    const dataSender = {
      ...values
    };
    mutate(dataSender);
  };

  const columns = [
    {
      title: "Nome",
      dataIndex: "profile",
      key: "profile",
      render: (text) => <p>{text.name}</p>
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      key: "transactions",
      render: (text) =>
        text?.map((item) => {
          return (
            <Tag color="blue" key={item.id}>
              {item.type_transaction}
            </Tag>
          );
        })
    }
  ];

  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="center" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Relatórios"
            >
              <Row className="my-25 text-center">
                <Col xs={24} xl={24} md={24}>
                  <Form
                    // {...layout}
                    layout="vertical"
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Row>
                      <Col md={24}>
                        <Form.Item name="title" label="Titulo">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Item name="access">
                          <Radio.Group buttonStyle="solid">
                            <Radio.Button value="99">Admin.</Radio.Button>
                            <Radio.Button value="50">G.N.</Radio.Button>
                            <Radio.Button value="1">Investidor</Radio.Button>
                            <Radio.Button value="0">Desativado</Radio.Button>
                            <Radio.Button value="20">Escritorio</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item name="date_range">
                          <RangePicker format={dateFormat} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={6}>
                        <Form.Item name="type">
                          <Checkbox.Group
                            style={{
                              width: "100%"
                            }}
                          >
                            <Checkbox
                              value="transaction"
                              onClick={handleOpenTypeTransactions}
                            >
                              Transaçoes
                            </Checkbox>
                            <Checkbox value="wallet">Carteira</Checkbox>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        {openTransactionsType ? (
                          <Form.Item name="type_transaction">
                            <Checkbox.Group
                              style={{
                                width: "100%"
                              }}
                            >
                              <Row>
                                <Col span={24}>
                                  <Checkbox value="1">
                                    Rentabilidade Mensal
                                  </Checkbox>
                                  <Checkbox value="2">
                                    Novos Investimentos
                                  </Checkbox>
                                  <Checkbox value="3">Bonus</Checkbox>
                                  <Checkbox value="5">Saque</Checkbox>
                                  <Checkbox value="8">
                                    Reinvestimento Automatico
                                  </Checkbox>
                                  <Checkbox value="90">
                                    Solicitaçoes Saque
                                  </Checkbox>
                                  <Checkbox value="99">
                                    Solicitaçoes Especiais
                                  </Checkbox>
                                </Col>
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>

                    <Form.Item name="status">
                      <Radio.Group>
                        <Radio.Button value="10">Ativos</Radio.Button>
                        <Radio.Button value="1">Inativos</Radio.Button>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item name="users">
                      <Select
                        mode="multiple"
                        tagRender={(props) => {
                          const { label, value } = props;
                          return (
                            <Tag color="cyan" key={value} closable={true}>
                              {label}
                            </Tag>
                          );
                        }}
                        tokenSeparators={[","]}
                        // onChange={handleChange}
                        // options={(dataSelectPeople || []).map((d) => ({
                        //   value: d?.profile?.name,
                        //   label: d?.profile?.name
                        // }))}
                      >
                        {statusPeople === "success" &&
                          dataSelectPeople?.map((item) => (
                            <>
                              <Option key={item?.id} value={item?.user_id}>
                                {item?.profile?.name}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={!!isLoading}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {responseReports ? (
          <Report00001Pdf
            title={responseReports?.title}
            data={responseReports?.user}
            amount={responseReports?.wallet}
          />
        ) : (
          ""
        )}
      </Content>
    </div>
  );
};

export default List;
