import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Table, Tag } from "antd";
import React from "react";
import { useCopyUpdateNewAportRequest } from "../../Hooks/copy/useCopy.query";
import { convertTimestampToDate } from "../../helpers/convertTimestampToDate";

const date = new Date();
const dateExtencil = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}`;

const TableCopy = ({ data }) => {
  console.log("data", data);
  const queryClient = useQueryClient();
  let obj = JSON.parse(data.new_aport);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      rowScope: "row"
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date) => convertTimestampToDate(date)
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$ ${amount}`
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { isAproved }) => (
        <>
          {isAproved === 0 && <Tag color="gray">Aguardando Liberação</Tag>}
          {isAproved === 1 && <Tag color="green">Liberado</Tag>}
        </>
      )
    },
    {
      title: "Aprovar",
      dataIndex: "aprove",
      key: "aprove",
      render: (_, doc) => (
        <>
          {doc?.isAproved === 1 ? (
            ""
          ) : (
            <Button
              type="primary"
              size="large"
              onClick={() => handlerAprove(doc)}
            >
              Aprovar
            </Button>
          )}
        </>
      )
    }
  ];

  const { mutateAsync } = useMutation(useCopyUpdateNewAportRequest, {
    onSettled: () => {
      queryClient.invalidateQueries("manager-copys");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("manager-copys");
    }
  });

  const handlerAprove = (dataDoc) => {
    const dataSender = {
      idNewAport: dataDoc.id,
      id: data.id,
      aproved: 1
    };
    console.log(">>>>", dataSender);
    mutateAsync(dataSender);
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={obj}
        className="ant-border-space custom-row-hover"
        bordered
        pagination={{
          pageSize: 10
        }}
        scroll={{
          y: 540
        }}
      />
    </>
  );
};

export default TableCopy;
