import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  notification
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePromotionsRequest } from "../../Hooks/promotions/usePromotions.query";
import Title from "antd/es/typography/Title";

// import { Container } from './styles';
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function PlansCreate() {
  const { notification } = App.useApp();
  const [minAmount, setMinAmount] = useState(0);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data } = usePromotionsRequest();
  const dataPromotions = data?.data.data;

  const createPlan = (user) => {
    axios
      .post(`${process.env.REACT_APP_API}/v1/plan/create`, user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        console.log("RES", res);
        if (res.status === 400) {
          notification.error({
            message: "ERRO AO SALVAR",
            description: res.message
          });
          return;
        }
        notification.success({
          message: "Sucesso ao criar",
          description: "Plano criado com sucesso."
        });
        form.resetFields();
        queryClient.invalidateQueries("planos");
        navigate("/planos");
      })
      .catch((err) => {
        notification.error({
          message: "Notification Title",
          description: err.data.data
        });
      });
  };
  const { mutate, isLoading } = useMutation(createPlan, {
    // onSuccess: () => {
    //   queryClient.invalidateQueries("planos");
    //   notification.success({
    //     message: "Sucesso",
    //     description: "Plano criado com sucesso."
    //   });
    //   form.resetFields();
    //   navigate("/planos");
    // }
  });
  const onFinish = (inputForm) => {
    const data = {
      ...inputForm,
      gains_between: `${inputForm.start_gain} - ${inputForm.end_gain}`
    };

    mutate(data);
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Criar Plano</Title>
      </Flex>
      <Divider />

      <Content className="p-0">
        <Row gutter={[24, 5]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title=""
            >
              <Row gutter={[24, 5]} className="my-25 text-center">
                <Col xs="20" xl={20}>
                  <Form
                    // layout="inline"
                    {...layout}
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                  >
                    <Form.Item
                      name="plan"
                      label="Nome Plano"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Ganhos variantes de clientes">
                      <Space align="start" direction="horizontal">
                        <Form.Item name="start_gain" label="de">
                          <InputNumber
                            style={{
                              width: "100%"
                            }}
                            min={0}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value?.replace("%", "")}
                            onBlur={(value) =>
                              setMinAmount(parseFloat(value.target.value))
                            }
                          />
                        </Form.Item>
                        <Form.Item name="end_gain" label="até">
                          <InputNumber
                            style={{
                              width: "100%"
                            }}
                            min={minAmount}
                            max={100}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value?.replace("%", "")}
                          />
                        </Form.Item>
                      </Space>
                    </Form.Item>
                    <Form.Item name="gains_manager" label="Ganho do G.N.">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        min={0}
                        max={10}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace("%", "")}
                      />
                    </Form.Item>
                    <Form.Item
                      name="contract_time"
                      label="Tempo de contrato em meses"
                    >
                      <InputNumber
                        min={1}
                        style={{
                          width: "100%"
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="initial_value" label="Valor inicial">
                      <InputNumber
                        style={{
                          width: "100%"
                        }}
                        defaultValue={1000}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>

                    <Form.Item name="promotion" label="Promoção">
                      <Select>
                        {dataPromotions?.map((item) => (
                          <Option key={item?.id} value={item?.id}>
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        loading={isLoading}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
}

export default PlansCreate;
