import { SearchOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Table,
  notification
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import "dayjs/locale/pt-br";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import { useInvestorsFetchRequest } from "../../Hooks/investor/useInvestor.query";
import { useTransactionPostRequest } from "../../Hooks/transactions/useTransactions.query";
import { formatCurrency } from "../../helpers/moneyFormat";
import { typeFormat } from "../../helpers/formatType";

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 }
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 }
};

const RetroEntry = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState(null);
  const [userCurrency, setUserCurrency] = useState(null);
  const [amountAvailableForWithDrawal, setAmountAvailableForWithDrawal] =
    useState(0);
  const [entryDate, setEntryDate] = useState(null);
  const [entryType, setEntryType] = useState(0);
  // const [inputValues, setInputValues] = useState({ prefix: '', name: '' })
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  const { data: dataInvestor, isLoading: loadingUsers } =
    useInvestorsFetchRequest("wallet,transactions");
  const investors = dataInvestor?.data?.data;

  const { mutateAsync, isLoading: sending } = useMutation(
    useTransactionPostRequest,
    {
      onSettled: () => {
        console.log("onSettled");
      },
      onSuccess: () => {
        queryClient.invalidateQueries("adminInvestors");
        setIsModalVisible(false);
        form.resetFields();
        api.open({
          message: "Sucesso",
          description: "Lançamento feito com sucesso."
        });
      }
    }
  );

  const handleClickRetroEntry = (e, currency, withdrawal) => {
    setUserCurrency(currency);
    setId(e);
    setAmountAvailableForWithDrawal(withdrawal);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const onChangeDatePicker = (date, dateString) => {
    setEntryDate(dateString);
  };
  const onChangeCheckButton = (e) => {
    setEntryType(e.target.value);
  };

  const onFinish = (values) => {
    const entry = {
      type: entryType,
      amount: values.amount,
      investor: values.id,
      register: entryDate,
      percentage: values.percent,
      comment: values.comment
    };
    mutateAsync(entry);
  };

  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close
    }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      )
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name")
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: "15%"
    }
  ];
  const dataUsers = investors?.map((investor) => {
    return {
      key: investor?.id,
      name: investor?.name,
      entryLength: investor?.transactions?.length,
      transaction: investor?.transactions,
      currency: investor?.currency,
      action: (
        <Space size="middle">
          <Link
            onClick={() =>
              handleClickRetroEntry(
                investor?.user_id,
                investor?.currency,
                investor?.wallet?.available_for_withdrawal
              )
            }
          >
            Novo lançamento
          </Link>
        </Space>
      )
    };
  });

  const typeMap = {
    0: "",
    1: (
      <>
        <Form.Item name="percent">
          <Input prefix="%" />
        </Form.Item>
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker
            picker="month"
            locale={locale}
            onChange={onChangeDatePicker}
          />
        </Form.Item>
      </>
    ),
    2: (
      <>
        <Form.Item name="amount">
          <Input prefix={userCurrency} />
        </Form.Item>
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker locale={locale} onChange={onChangeDatePicker} />
        </Form.Item>
      </>
    ),
    3: (
      <>
        <Form.Item name="amount">
          <Input prefix={userCurrency} />
        </Form.Item>
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker locale={locale} onChange={onChangeDatePicker} />
        </Form.Item>
      </>
    ),
    5: (
      <>
        <Row wrap={false} gutter={[16, 16]} style={{ marginBottom: "25px" }}>
          <Col flex="auto">
            <Descriptions title="" layout="vertical" bordered>
              <Descriptions.Item label="Valor disponivel para saque">
                {formatCurrency(amountAvailableForWithDrawal, userCurrency)}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Form.Item name="amount">
          <Input prefix={userCurrency} />
        </Form.Item>
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker locale={locale} onChange={onChangeDatePicker} />
        </Form.Item>
      </>
    ),
    8: (
      <>
        <Row wrap={false} gutter={[16, 16]} style={{ marginBottom: "25px" }}>
          <Col flex="auto">
            <Descriptions title="" layout="vertical" bordered>
              <Descriptions.Item label="Valor a ser reinvestido">
                {formatCurrency(amountAvailableForWithDrawal, userCurrency)}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        {/* <Form.Item name="amount">
          <Input prefix="$" />
        </Form.Item> */}
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker locale={locale} onChange={onChangeDatePicker} />
        </Form.Item>
      </>
    ),
    99: (
      <>
        <Row wrap={false} gutter={[16, 16]} style={{ marginBottom: "25px" }}>
          {/* <Col flex="auto">
            <Descriptions title="" layout="vertical" bordered>
              <Descriptions.Item label="Valor a ser reinvestido">
                {amountAvailableForWithDrawal}
              </Descriptions.Item>
            </Descriptions>
          </Col> */}
        </Row>
        <Form.Item name="comment">
          <Input />
        </Form.Item>
        <Form.Item name="month" rules={[{ required: false }]}>
          <DatePicker locale={locale} onChange={onChangeDatePicker} />
        </Form.Item>
      </>
    )
  };

  const radioOptions = {
    1: "% Mensal",
    2: "Novo investimento",
    5: "Saque",
    8: "Reinvestimento",
    99: "Campo Mensagem"
  };

  return (
    <div className="layout-content">
      {contextHolder}
      {/* <Row> */}
      {/* <Col> */}
      <Card
        bordered={false}
        className="criclebox tablespace mb-24"
        title="Investidores"
      >
        <Table
          loading={loadingUsers}
          className="custom-row-hover"
          columns={columns}
          size="middle"
          expandable={{
            expandedRowRender: (record) => (
              <p
                style={{
                  margin: 0
                }}
              >
                {
                  <Table
                    size="small"
                    bordered
                    sticky
                    tableLayout="auto"
                    dataSource={record?.transaction?.map(
                      (transaction, index) => {
                        const description = transaction.description;
                        const commentsArray = description.replace(
                          /\|/g,
                          "\n - "
                        );
                        return {
                          key: transaction.id,
                          date: transaction.date,
                          type: `${transaction.percent ?? ""} ${typeFormat(
                            transaction.type_transaction
                          )}`,
                          amount: formatCurrency(
                            transaction.amount_money,
                            record.currency
                          ),
                          description: commentsArray
                        };
                      }
                    )}
                    columns={[
                      {
                        title: "Data de registro",
                        dataIndex: "date",
                        key: "date",
                        width: "15%"
                      },
                      {
                        title: "Tipo de entrada",
                        dataIndex: "type",
                        key: "type",
                        width: "15%"
                      },
                      {
                        title: "Valor",
                        dataIndex: "amount",
                        key: "amount",
                        width: "15%"
                      },
                      {
                        title: "Descrição",
                        dataIndex: "description",
                        key: "description",
                        render: (_, text) => (
                          <div style={{ whiteSpace: "pre-line" }}>{_}</div>
                        ),
                        ellipsis: true,
                        width: "auto"
                        // width: 650
                      }
                    ]}
                  />
                }
              </p>
            )
            // rowExpandable: (record) => record.transaction > 0,
          }}
          dataSource={dataUsers}
        />
      </Card>
      {/* </Col> */}
      {/* </Row> */}

      <Modal
        title=""
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        okText="Tem certeza?"
        confirmLoading
        maskStyle={{ backdropFilter: "10px" }}
        footer={true}
      >
        <Row gutter={[8, 16]} justify="center">
          <Form
            {...layout}
            form={form}
            name="create-user"
            onFinish={onFinish}
            fields={[
              {
                name: ["id"],
                value: id
              }
            ]}
          >
            <Radio.Group
              style={{ marginBottom: "25px" }}
              id="entryType"
              onChange={onChangeCheckButton}
              buttonStyle="solid"
              name="entryType"
              value={entryType}
            >
              {Object.keys(radioOptions).map((key) => (
                <Radio.Button name="entryType" key={key} value={key}>
                  {radioOptions[key]}
                </Radio.Button>
              ))}
            </Radio.Group>

            {typeMap[entryType]}

            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                loading={!!sending}
                style={{ width: "100%", marginRight: 0 }}
                type="primary"
                htmlType="submit"
                hidden={!(entryType > 0)}
              >
                CRIAR
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Modal>
    </div>
  );
};

export default RetroEntry;
