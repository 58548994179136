import React from "react";
import { useParams } from "react-router-dom";
import {
  useOfficeFetchRequest,
  useUpdateOffice
} from "../../Hooks/offices/offices.query";
import { Button, Col, Divider, Form, Input, Row, notification } from "antd";
import Title from "antd/es/skeleton/Title";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const EditOffice = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { data } = useOfficeFetchRequest(id);
  const office = data?.data;

  const { isLoading, mutate } = useMutation(useUpdateOffice, {
    onSuccess: (data) => {
      console.log(data);
      api.success({
        message: "Sucesso",
        description: "Escritorio atualizado com sucesso"
      });
    }
  });

  const onFinish = (values) => {
    const data = {
      id,
      ...values
    };
    mutate(data);
  };

  return (
    <Row gutter={[24, 5]}>
      <Col xs={24} xl={24} md={24}>
        <Title level={4}>Documentos</Title>
        <Divider />

        <Row gutter={[24, 5]} className="my-25 text-center">
          <Col xs={24} xl={24} md={24}>
            <Form
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              // layout="vertical"
              {...layout}
              form={form}
              name="create-user"
              onFinish={onFinish}
              fields={[
                {
                  name: ["name"],
                  value: office?.name
                },
                {
                  name: ["phone"],
                  value: office?.phone
                },
                {
                  name: ["whatsapp"],
                  value: office?.whatsapp
                },
                {
                  name: ["postal_code"],
                  value: office?.officeAddress?.postal_code
                },
                {
                  name: ["street"],
                  value: office?.officeAddress?.street
                },
                {
                  name: ["neighborhood"],
                  value: office?.officeAddress?.neighborhood
                },
                {
                  name: ["city"],
                  value: office?.officeAddress?.city
                },
                {
                  name: ["address_id"],
                  value: office?.address_id
                }
              ]}
            >
              <Form.Item name="name" label="Nome">
                <Input />
              </Form.Item>
              <Form.Item name="phone" label="Telefone">
                <Input />
              </Form.Item>
              <Form.Item name="whatsapp" label="Whatsapp">
                <Input />
              </Form.Item>
              <Divider />
              <Form.Item name="postal_code" label="Cep">
                <Input />
              </Form.Item>
              <Form.Item name="street" label="Rua">
                <Input />
              </Form.Item>
              <Form.Item name="neighborhood" label="Bairro">
                <Input />
              </Form.Item>
              <Form.Item name="city" label="Cidade">
                <Input />
              </Form.Item>
              <Form.Item name="state" label="Estado">
                <Input />
              </Form.Item>
              <Form.Item name="address_id" label="ID" hidden>
                <Input disabled />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button
                  // loading={!!isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EditOffice;
