import {
  App,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row
} from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useNavigate } from "react-router-dom";
import locale from "antd/es/date-picker/locale/pt_BR";
import { useMutation } from "@tanstack/react-query";
import { usePromotionPost } from "../../Hooks/promotions/usePromotions.query";
import dayjs from "dayjs";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

function formatDate(date) {
  date.substr(0, 10);
}

const CreatePromotion = () => {
  const { notification } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(usePromotionPost, {
    onSuccess: (data) => {
      notification.success({
        message: "Sucesso",
        description: "Promoção criada com sucesso."
      });
      form.resetFields();
      navigate("/promocoes");
    }
  });
  const onFinish = (sender) => {
    const data = {
      ...sender,
      start_date: dayjs(sender.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(sender.end_date).format("YYYY-MM-DD")
    };
    mutate(data);
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        <Title level={4}>Nova Promoção</Title>
      </Flex>
      <Divider />

      <Card bordered={false} className="criclebox tablespace mb-24">
        <Row gutter={[24, 5]} className="my-25 text-center">
          <Col xs="20" xl={20}>
            <Form
              {...layout}
              form={form}
              name="create-user"
              onFinish={onFinish}
            >
              <Form.Item
                name="name"
                label="Nome identificador"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="title"
                label="Titulo da Campanha"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label="Descrição"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="start_date"
                label="Data de inicio"
                rules={[{ required: true }]}
              >
                <DatePicker picker="date" locale={locale} />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="Data de fim"
                rules={[{ required: true }]}
              >
                <DatePicker picker="date" locale={locale} />
              </Form.Item>
              <Form.Item name="extra_value" label="Pagamento extra">
                <Input />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  loading={isLoading}
                  style={{ width: "100%", marginRight: 0 }}
                  type="primary"
                  htmlType="submit"
                >
                  CRIAR
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CreatePromotion;
