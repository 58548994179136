import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Progress, Row } from "antd";
import { IKContext, IKUpload } from "imagekitio-react";
import React, { useState } from "react";
import { useUploadDocument } from "../../Hooks/uploads/useUpload.query";

const DocumentAssigned = ({ id, title }) => {
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  // IMAGEKIT UPLOAD
  const publicKey = "public_Hqt+AgHm0gjcWTX7lFzrSP0QwhE=";
  const urlEndpoint = "https://ik.imagekit.io/qqrtx9mgqo/";
  const authenticationEndpoint = `${process.env.REACT_APP_API}/v1/site/auth`;
  // const folder = `${id}/document/${btoa(title)}`

  const { mutateAsync: uploadDocument } = useMutation(useUploadDocument, {
    onSettled: () => {
      queryClient.invalidateQueries("adminInvestor");
    },
    onSuccess: () => {
      queryClient.invalidateQueries("adminInvestor");
    }
  });

  const onError = (err) => {
    console.log("error: ", err);
    // toast.error(`${err}`, {
    //   position: toast.POSITION.TOP_CENTER
    // });
  };

  const onSuccess = (res) => {
    const images = res.url;
    const dataDocument = {
      images,
      id,
      title
    };
    // toast.success('Imagem enviada com sucesso', {
    //   position: toast.POSITION.TOP_CENTER
    // });
    uploadDocument(dataDocument);
  };

  const onUploadStart = (evt) => {};

  const onUploadProgress = (event) => {
    const percent = Math.floor((event.loaded / event.total) * 100);
    setProgress(percent);
  };

  return (
    <>
      <Row>
        <IKContext
          publicKey={publicKey}
          urlEndpoint={urlEndpoint}
          authenticationEndpoint={authenticationEndpoint}
        >
          <IKUpload
            fileName={`${btoa(title)}-${id}`}
            onError={onError}
            onSuccess={onSuccess}
            useUniqueFileName={false}
            folder={"/document"}
            onUploadStart={onUploadStart}
            onUploadProgress={onUploadProgress}
          />
        </IKContext>
      </Row>
      <Row>{progress > 0 ? <Progress percent={progress} /> : null}</Row>
    </>
  );
};

export default DocumentAssigned;
