import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  notification
} from "antd";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { usePlanFetchRequest } from "../../Hooks/plans/usePlans.query";
import { useQueryClient } from "@tanstack/react-query";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const PlanEdit = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const { data } = usePlanFetchRequest(id);
  const plan = data?.data;

  console.log("plan", plan);

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <div className="layout-content">
      <Flex justify="space-between" align="center">
        {/* <Button className="">
          <NavLink to="criar">+ Cadastrar novo</NavLink>
        </Button> */}
      </Flex>
      <Divider />
      <Row gutter={[24, 5]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={`Editar plano ${plan?.plan}`}
          >
            <Form
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
              {...layout}
              form={form}
              name="create-user"
              onFinish={onFinish}
              fields={[
                {
                  name: ["contract_time"],
                  value: plan?.contract_time
                },
                {
                  name: ["gains_between"],
                  value: plan?.gains_between
                },
                {
                  name: ["initial_value"],
                  value: plan?.initial_value
                },
                {
                  name: ["plan"],
                  value: plan?.plan
                },
                {
                  name: ["price"],
                  value: plan?.price
                },
                {
                  name: ["promotion"],
                  value: plan?.promotion
                },

                {
                  name: ["warranty"],
                  value: plan?.warranty
                }
              ]}
            >
              <Form.Item
                name="plan"
                label="Nome Plano"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="gains_between"
                label="Porcentagem de ganho entre"
              >
                <InputNumber
                  defaultValue={100}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "")}
                  onChange={onChange}
                />
                {/* <Input /> */}
              </Form.Item>
              <Form.Item name="gains_manager" label="Ganho do G.N.">
                <Input />
              </Form.Item>
              <Form.Item
                name="contract_time"
                label="Tempo de contrato em meses"
              >
                <Input />
              </Form.Item>
              <Form.Item name="initial_value" label="Valor inicial">
                <Input />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
  kkk;
};

export default PlanEdit;
