import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
  notification
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
// import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 }
};

const { Option } = Select;

const CreateUser = () => {
  const [entryDate, setEntryDate] = useState(null);
  const [addSubPlan, setAddSubPlan] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const queryClient = useQueryClient();

  const { data: dataSelectPlan, status: statusPlan } = useQuery(
    ["plans", 1],
    () =>
      axios
        .get(`${process.env.REACT_APP_API}/v1/plan`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        })
        .then((res) => {
          return res.data.data;
        })
  );
  const { data: dataSelectPeopleGN, status: statusPeopleGN } = useQuery(
    ["people", 1],
    () =>
      axios
        .get(
          `${process.env.REACT_APP_API}/v1/profile/people-by-role?role=50&expand=profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                process.env.REACT_APP_ACCESS_TOKEN
              )}`
            }
          }
        )
        .then((res) => {
          return res.data.data;
        })
  );
  const { data: dataSelectOffice, status: statusOffice } = useQuery(
    ["offices"],
    () =>
      axios
        .get(`${process.env.REACT_APP_API}/v1/offices`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              process.env.REACT_APP_ACCESS_TOKEN
            )}`
          }
        })
        .then((res) => {
          return res.data.data;
        })
  );

  const createUser = async (user) => {
    await axios
      .post(`${process.env.REACT_APP_API}/v1/profile/create`, user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`
        }
      })
      .then((res) => {
        form.resetFields();
        api.open({
          message: "Sucesso",
          description: res.message
        });
        navigate("/investidor");
      })
      .catch((err) => {
        api.open({
          message: "erro",
          description: `erro ao criar ${err.message}`
        });
      });
  };
  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (data) => {
      api.open({
        message: "Sucesso 2",
        description: data.message
      });
      queryClient.invalidateQueries("people");
    }
  });

  const onFinish = (user) => {
    // const newDate = moment(user.start_of_contract).format("DD/MM/YYYY");
    const newDate = user.start_of_contract;
    const data = {
      ...user,
      start_of_contract: newDate
    };
    mutate(data);
  };

  const onChangeDatePicker = (date, dateString) => {
    setEntryDate(dateString);
  };

  const handleCheckSubplan = (checked) => {
    setAddSubPlan(checked.target.checked);
    form.resetFields(["plan_message"]);
  };
  return (
    <div className="layout-content">
      {contextHolder}
      <Content className="p-0">
        <Row gutter={[24, 2]} justify="center" span="4">
          <Col xs={24} xl={24} md={24}>
            <Card
              bordered={true}
              className="criclebox tablespace mb-24"
              title="Cadastrar"
            >
              <Row className="my-25 text-center">
                <Col xs={24} xl={24} md={24}>
                  <Form
                    {...layout}
                    layout="horizontal"
                    form={form}
                    name="create-user"
                    onFinish={onFinish}
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Form.Item
                      name="name"
                      label="Nome"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="plan"
                      label="Plano"
                      // rules={[{ required: true }]}
                    >
                      <Select>
                        {statusPlan === "loading" && (
                          <Option key="loading" disabled>
                            Loading...
                          </Option>
                        )}
                        {statusPlan === "success" &&
                          dataSelectPlan.map((item, index) => (
                            <Option key={index + 1} value={item.id}>
                              {item.plan}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="office" label="Escritorio">
                      <Select>
                        {statusOffice === "loading" && (
                          <Option key="loading" disabled>
                            Loading...
                          </Option>
                        )}
                        {statusOffice === "error" && (
                          <Option key="loading" disabled>
                            erro
                          </Option>
                        )}
                        {statusOffice === "success" &&
                          dataSelectOffice?.map((item) => (
                            <Option key={item?.id} value={item?.id}>
                              {item?.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="whatsapp" label="Whatsapp">
                      <Input />
                    </Form.Item>

                    <Form.Item name="is_demo" label="DEMO" style={{ left: 0 }}>
                      <Col span={4}>
                        <Switch />
                      </Col>
                    </Form.Item>

                    <Form.Item
                      name="subplan"
                      label="Sub Plano"
                      valuePropName="checked"
                    >
                      <Col span={4}>
                        <Checkbox
                          // checked={addSubPlan}
                          onChange={handleCheckSubplan}
                        >
                          Sub Plano
                        </Checkbox>
                      </Col>
                    </Form.Item>
                    {addSubPlan ? (
                      <Form.Item name="plan_message" label="Mensagem do plano">
                        <Input />
                      </Form.Item>
                    ) : (
                      ""
                    )}

                    <Form.Item
                      name="manager"
                      label="G.N"
                      rules={[{ required: true }]}
                    >
                      <Select>
                        {statusPeopleGN === "loading" && (
                          <Option key="loading" disabled>
                            Loading...
                          </Option>
                        )}
                        {statusPeopleGN === "error" && (
                          <Option key="loading" disabled>
                            erro
                          </Option>
                        )}
                        {statusPeopleGN === "success" &&
                          dataSelectPeopleGN?.map((item) => (
                            <>
                              <Option key={item?.id} value={item?.user_id}>
                                {item?.profile?.name}
                              </Option>
                            </>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="currency"
                      label="Moeda"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Select
                        placeholder="Selecione uma opçao de moeda"
                        allowClear
                      >
                        <Option value="USD">Dollar</Option>
                        <Option value="BRL">Real</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="username"
                      label="CPF/CNPJ"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      label="Senha"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="start_of_contract"
                      label="Data de entrada (contrato)"
                    >
                      <DatePicker
                        locale={locale}
                        onChange={onChangeDatePicker}
                      />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                      <Button
                        loading={!!isLoading}
                        style={{ width: "100%", marginRight: 0 }}
                        type="primary"
                        htmlType="submit"
                      >
                        CRIAR
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default CreateUser;
